<template>
  <v-container data-app grid-list-xl fluid style="margin-top: 1vh">
    <ShowMessage ref="message"></ShowMessage>
    <v-layout row wrap>
      <v-flex>
        <BaseWidget title="Emissão de Viagem">
          <v-layout row wrap>
            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="2" style="text-align: left">
                <v-label style="margin-top: 10px"
                  ><b>Pesquisar por:</b></v-label
                >
                <v-select
                  v-model="pesquisaSelecionado"
                  :items="listaPesquisa"
                  :item-text="'description'"
                  solo
                ></v-select>
              </v-col>

              <v-col class="d-flex" cols="12" sm="6">
                <v-label><b style="color: transparent">P</b></v-label>
                <v-text-field
                  :label="
                    'Digite os dados do ' + pesquisaSelecionadoDescription
                  "
                  :disabled="!pesquisaSelecionado"
                  v-if="pesquisaSelecionado != 'Transportador'"
                  solo
                  v-model="tfPesquisa"
                  @input="tfPesquisa = $event.toUpperCase()"
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="2" style="text-align: left">
                <v-label style="margin-top: 10px"><b>Situação:</b></v-label>
                <v-select
                  v-model="statusSelecionado"
                  :items="listaStatus"
                  :item-text="'description'"
                  solo
                ></v-select>
              </v-col>

              <v-col
                class="d-flex"
                cols="12"
                sm="2"
                style="text-align: right; align-items: center; display: flex"
              >
                <div class="text-center mt-3">
                  <v-btn
                    dark
                    rounded=""
                    style="background-color: #757575"
                    @click="filtrar()"
                  >
                    <v-icon left> mdi-filter </v-icon>
                    <b>Filtrar</b>
                  </v-btn>
                </div>
              </v-col>

              <br />
              <v-col
                class="d-flex"
                cols="12"
                sm="4"
                style="text-align: left"
              ></v-col>
            </v-row>
          </v-layout>
        </BaseWidget>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar style="background-color: #808080" class="elevation-1">
          <v-toolbar-title>
            <h5 style="color: white">Lista de Emissão de Viagem</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #757575"
            @click="novaEmissaoViagem()"
          >
            <v-icon left> mdi-plus </v-icon>
            <b>Novo</b>
          </v-btn>
        </v-toolbar>
        <v-skeleton-loader v-if="showLoader" type="table"></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headers"
              :items="listaEmissaoViagem"
              v-model="selected"
              item-key="idDocumento"
              class="elevation-1"
              :single-select="singleSelect"
            >
              <template v-slot:item.mdfe="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColorMdfe(item.mdfe)"
                  dark
                  >{{ item.mdfe ? "SIM" : "NAO" }}
                </v-chip>
              </template>

              <template v-slot:item.prepararCte="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColorPrepararCte(item.prepararCte)"
                  dark
                  >{{ item.prepararCte ? "SIM" : "NAO" }}
                </v-chip>
              </template>

              <template v-slot:item.prepararMdfe="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColorPrepararMdfe(item.prepararMdfe)"
                  dark
                  >{{ item.prepararMdfe ? "SIM" : "NAO" }}
                </v-chip>
              </template>

              <template v-slot:item.validado="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColorValidado(item.validado)"
                  dark
                  >{{ item.validado ? "SIM" : "NAO" }}
                </v-chip>
              </template>

              <template v-slot:item.acoes="{ item }">
                <v-btn @click="editarEmissaoViagem(item)" small>
                  <v-icon
                    :style="{ color: '#757575' }"
                    title="Editar Emissão de Viagem"
                    >mdi-pencil</v-icon
                  >
                </v-btn>

                <v-btn @click="listarNotaFiscal(item)" small>
                  <v-icon
                    :style="{ color: '#757575' }"
                    title="Importação Nota Fiscal"
                    >mdi-receipt</v-icon
                  >
                </v-btn>

                <v-btn @click="listarCte(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Preparar Cte"
                    >mdi-file-document-outline</v-icon
                  >
                </v-btn>

                <v-btn @click="listarMdfe(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Preparar Mdfe"
                    >mdi-truck-check</v-icon
                  >
                </v-btn>

                <v-btn @click="deletarViagem(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Excluir Viagem"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-flex>
      </v-flex>
    </v-layout>

    <v-dialog v-model="dialogCadastro" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastro de Emissão de Viagem</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-text-field
                label="Código"
                v-model="item.id"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10">
              <v-text-field
                ref="descricaoInput"
                label="Descrição"
                maxlength="60"
                v-model="item.descricao"
                @input="item.descricao = $event.toUpperCase()"
                :rules="[
                  (v) =>
                    (!!v && v.length >= 5) ||
                    'A descrição deve ter no mínimo 5 caracteres',
                ]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="10">
              <v-select
                ref="participanteInput"
                v-model="item.idParticipante"
                :items="participantes"
                label="Transportador"
                item-text="nomeFantasia"
                item-value="id"
                @input="loadParticipantes"
              ></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <label>
                <input type="checkbox" v-model="item.mdfe" />
                Gerar MDF-e
              </label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelar"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvar"
            style="background-color: #757575; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogListaNotaFiscal">
      <v-card>
        <v-card-title>
          <h4>Lista de Notas Fiscais</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="Transportador"
                v-model="item.nomeFantasia"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Descrição"
                v-model="item.descricao"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                label="Qtde Notas"
                v-model="qtdeNotasFiscais"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                label="Valor Total Notas"
                v-model="totalNotasFiscaisFormatado"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-toolbar
          style="
            background-color: #808080;
            margin-left: 17px;
            margin-right: 17px;
          "
          class="elevation-1"
        >
          <v-toolbar-title>
            <h5 style="color: white">Notas Fiscais Lançadas</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #757575; margin-right: 5px"
            @click="novaNotaFiscal()"
          >
            <v-icon left> mdi-plus </v-icon>
            <b>Novo</b>
          </v-btn>

          <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #757575; margin-left: 5px"
            @click="importarNotaFiscal()"
          >
            <v-icon left> mdi-plus </v-icon>
            <b>Importar</b>
          </v-btn>
        </v-toolbar>

        <v-skeleton-loader
          v-if="showLoaderListaNotaFiscais"
          type="table"
        ></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headersNotaFiscal"
              :items="listaNotaFiscais"
              v-model="selectedNotaFiscal"
              item-key="chave"
              class="elevation-1"
              :single-select="singleSelect"
              hide-default-footer
            >
              <template v-slot:item.acoes="{ item }">
                <v-btn @click="editarNotaFiscal(item)" small>
                  <v-icon
                    :style="{ color: '#757575' }"
                    title="Editar Nota Fiscal"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn @click="deletarNotaFiscal(item)" small>
                  <v-icon
                    :style="{ color: '#757575' }"
                    title="Deletar Nota Fiscal"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-flex>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarListaNotaFiscal"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnPrepareCte"
            style="background-color: #757575; color: white"
            >Preparar Cte</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNotaFiscal" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Lançamento de Nota Fiscal</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-tabs v-model="tabNotaFiscal" background-color="#808080" dark>
            <v-tab href="#dadosNota">Dados Nota</v-tab>
            <v-tab href="#remetente">Remetente</v-tab>
            <v-tab href="#destinatario">Destinatário</v-tab>

            <v-tab-item value="dadosNota">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        ref="chaveInput"
                        maxlength="44"
                        label="Chave"
                        v-model="itemNotaFiscal.chave"
                        v-numbers-only
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="qtdeCargaFormatadoInput"
                        label="Qtde Volume"
                        v-model="itemNotaFiscal.qtdeCargaFormatado"
                        v-money="{ precision: 3, prefix: '' }"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="pesoCargaFormatadoInput"
                        label="Peso Carga"
                        v-model="itemNotaFiscal.pesoCargaFormatado"
                        v-money="{ precision: 3, prefix: '' }"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="valorCargaFormatadoInput"
                        label="Valor Carga R$"
                        v-model="itemNotaFiscal.valorCargaFormatado"
                        v-money="{ precision: 2, prefix: '' }"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="remetente">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="cpfCnpjRemetenteInput"
                        label="CNPJ"
                        v-model="itemNotaFiscal.cpfCnpjRemetenteFormatado"
                        v-mask="'##.###.###/####-##'"
                        maxlength="18"
                        @blur="consultarCnpjRemetente"
                        :append-outer-icon="limparCampoIcon"
                        @click:append-outer="limparDadosCompletoRemetente"
                        :readonly="somenteLeituraCnpjRemetente"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="8">
                      <v-text-field
                        ref="razaoSocialRemetenteInput"
                        label="Razão Social"
                        maxlength="60"
                        v-model="itemNotaFiscal.razaoSocialRemetente"
                        @input="
                          itemNotaFiscal.razaoSocialRemetente =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="8">
                      <v-text-field
                        ref="nomeFantasiaRemetenteInput"
                        label="Nome Fantasia"
                        maxlength="60"
                        v-model="itemNotaFiscal.nomeFantasiaRemetente"
                        @input="
                          itemNotaFiscal.nomeFantasiaRemetente =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="inscricaoEstadualRemetenteInput"
                        label="Inscrição Estadual"
                        maxlength="14"
                        v-model="itemNotaFiscal.inscricaoEstadualRemetente"
                        v-numbers-only
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="10">
                      <v-text-field
                        ref="logradouroRemetenteInput"
                        label="Logradouro"
                        maxlength="60"
                        v-model="itemNotaFiscal.logradouroRemetente"
                        @input="
                          itemNotaFiscal.logradouroRemetente =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        ref="numeroInput"
                        label="Número"
                        maxlength="10"
                        v-model="itemNotaFiscal.numeroRemetente"
                        @input="
                          itemNotaFiscal.numeroRemetente = $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="2">
                      <v-text-field
                        ref="cepRemetenteFormatadocepInput"
                        label="CEP"
                        v-model="itemNotaFiscal.cepRemetenteFormatado"
                        v-mask="'#####-###'"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        ref="bairroRemetenteInput"
                        label="Bairro"
                        maxlength="60"
                        v-model="itemNotaFiscal.bairroRemetente"
                        @input="
                          itemNotaFiscal.bairroRemetente = $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        ref="complementoRemetenteInput"
                        label="Complemento"
                        maxlength="60"
                        v-model="itemNotaFiscal.complementoRemetente"
                        @input="
                          itemNotaFiscal.complementoRemetente =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="2">
                      <v-select
                        ref="ufRemetenteInput"
                        v-model="itemNotaFiscal.ufRemetente"
                        :items="estados"
                        label="UF"
                        item-text="sigla"
                        item-value="sigla"
                        @input="loadMunicipiosRemetente"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="7">
                      <v-select
                        ref="municipioRemetenteInput"
                        v-model="itemNotaFiscal.municipioRemetente"
                        :items="municipiosRemetente"
                        label="Município"
                        item-text="nome"
                        item-value="nome"
                        @input="atualizarCodigoIbgeRemetente"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        ref="foneRemetenteFormatadoInput"
                        label="Fone"
                        v-model="itemNotaFiscal.foneRemetenteFormatado"
                        v-mask="'(##)####-####'"
                        maxlength="15"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="5">
                      <v-text-field
                        label="Email"
                        ref="emailRemetenteInput"
                        maxlength="60"
                        v-model="itemNotaFiscal.emailRemetente"
                        @input="
                          itemNotaFiscal.emailRemetente = $event.toLowerCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="7">
                      <v-text-field
                        label="Observação"
                        ref="observacaoRemetenteInput"
                        maxlength="200"
                        v-model="itemNotaFiscal.observacaoRemetente"
                        @input="
                          itemNotaFiscal.observacaoRemetente =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="destinatario">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="cpfCnpjDestinatarioInput"
                        label="CNPJ/CPF"
                        v-model="itemNotaFiscal.cpfCnpjDestinatarioFormatado"
                        @blur="consultarcpfCnpjDestinatario"
                        maxlength="18"
                        @input="
                          formatarCpfCnpj(
                            itemNotaFiscal.cpfCnpjDestinatarioFormatado
                          )
                        "
                        :append-outer-icon="limparCampoIcon"
                        @click:append-outer="limparDadosCompletoDestinatario"
                        :readonly="somenteLeituracpfCnpjDestinatario"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        ref="razaoSocialDestinatarioInput"
                        label="Razão Social"
                        maxlength="60"
                        v-model="itemNotaFiscal.razaoSocialDestinatario"
                        @input="
                          itemNotaFiscal.razaoSocialDestinatario =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="8">
                      <v-text-field
                        ref="nomeFantasiaDestinatarioInput"
                        label="Nome Fantasia"
                        maxlength="60"
                        v-model="itemNotaFiscal.nomeFantasiaDestinatario"
                        @input="
                          itemNotaFiscal.nomeFantasiaDestinatario =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="inscricaoEstadualDestinatarioInput"
                        label="Inscrição Estadual"
                        maxlength="14"
                        v-model="itemNotaFiscal.inscricaoEstadualDestinatario"
                        v-numbers-only
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="10">
                      <v-text-field
                        ref="logradouroDestinatarioInput"
                        label="Logradouro"
                        maxlength="60"
                        v-model="itemNotaFiscal.logradouroDestinatario"
                        @input="
                          itemNotaFiscal.logradouroDestinatario =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        ref="numeroInput"
                        label="Número"
                        maxlength="10"
                        v-model="itemNotaFiscal.numeroDestinatario"
                        @input="
                          itemNotaFiscal.numeroDestinatario =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="2">
                      <v-text-field
                        ref="cepDestinatarioFormatadocepInput"
                        label="CEP"
                        v-model="itemNotaFiscal.cepDestinatarioFormatado"
                        v-mask="'#####-###'"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        ref="bairroDestinatarioInput"
                        label="Bairro"
                        maxlength="60"
                        v-model="itemNotaFiscal.bairroDestinatario"
                        @input="
                          itemNotaFiscal.bairroDestinatario =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="5">
                      <v-text-field
                        ref="complementoDestinatarioInput"
                        label="Complemento"
                        maxlength="60"
                        v-model="itemNotaFiscal.complementoDestinatario"
                        @input="
                          itemNotaFiscal.complementoDestinatario =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="2">
                      <v-select
                        ref="ufDestinatarioInput"
                        v-model="itemNotaFiscal.ufDestinatario"
                        :items="estados"
                        label="UF"
                        item-text="sigla"
                        item-value="sigla"
                        @input="loadMunicipiosDestinatario"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="7">
                      <v-select
                        ref="municipioDestinatarioInput"
                        v-model="itemNotaFiscal.municipioDestinatario"
                        :items="municipiosDestinatario"
                        label="Município"
                        item-text="nome"
                        item-value="nome"
                        @input="atualizarCodigoIbgeDestinatario"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        ref="foneDestinatarioFormatadoInput"
                        label="Fone"
                        v-model="itemNotaFiscal.foneDestinatarioFormatado"
                        v-mask="'(##)####-####'"
                        maxlength="15"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="5">
                      <v-text-field
                        label="Email"
                        ref="emailDestinatarioInput"
                        maxlength="60"
                        v-model="itemNotaFiscal.emailDestinatario"
                        @input="
                          itemNotaFiscal.emailDestinatario =
                            $event.toLowerCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="7">
                      <v-text-field
                        label="Observação"
                        maxlength="200"
                        v-model="itemNotaFiscal.observacaoDestinatario"
                        @input="
                          itemNotaFiscal.observacaoDestinatario =
                            $event.toUpperCase()
                        "
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarNotaFiscal"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarNotaFiscal"
            style="background-color: #757575; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogImportarNotaFiscal" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Selecione a NF-e (XML)</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                v-model="xmlNfeFile"
                label="Clique para Carregar a Nota Fiscal (.xml)"
                accept=".xml"
                @change="onXmlNfeFileChange"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarImportarNotaFiscal"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnImportarNotaFiscal"
            style="background-color: #757575; color: white"
            >Importar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogListaCte">
      <v-card>
        <v-card-title>
          <h4>CTE</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                ref="produtoPredominanteAtualInput"
                label="Produto Predominante"
                v-model="itemAtualizarCte.produtoPredominante"
                @input="
                  itemAtualizarCte.produtoPredominante = $event.toUpperCase()
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                ref="valorFreteAtualInput"
                label="Valor Frete R$"
                v-model="itemAtualizarCte.valorFreteFormatado"
                v-money="{ precision: 2, prefix: '' }"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                label="Observação"
                v-model="itemAtualizarCte.observacao"
                @input="itemAtualizarCte.observacao = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                color="primary"
                @click="clickBtnAtualizarCte"
                style="background-color: #757575; color: white"
                >Atualizar Ctes</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>

        <v-toolbar
          style="
            background-color: #808080;
            margin-left: 17px;
            margin-right: 17px;
          "
          class="elevation-1"
        >
          <v-toolbar-title>
            <h5 style="color: white">Lista de Ctes</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #757575; margin-left: 5px"
            @click="importarNotaFiscal()"
          >
            <v-icon left> mdi-check-circle-outline</v-icon>
            <b>Validar Ctes</b>
          </v-btn> -->
        </v-toolbar>

        <v-skeleton-loader
          v-if="showLoaderListaCte"
          type="table"
        ></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headersCte"
              :items="listaCtes"
              v-model="selectedCte"
              item-key="chave"
              class="elevation-1"
              :single-select="singleSelect"
              hide-default-footer
            >
              <template v-slot:item.status="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColorStatus(item.status)"
                  dark
                  >{{ getStatusText(item.status) }}
                </v-chip>
              </template>

              <template v-slot:item.acoes="{ item }">
                <v-btn @click="editarCte(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Editar Cte"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn @click="validarCte(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Validar Cte"
                    >mdi-check-circle</v-icon
                  >
                </v-btn>
                <v-btn
                  @click="
                    downloadCte(item, item.caminhoCte, item.numeroCte, 'pdf')
                  "
                  small
                >
                  <v-icon :style="{ color: '#757575' }" title="Download Cte"
                    >mdi-download</v-icon
                  >
                </v-btn>
                <v-btn @click="cancelarCte(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Cancelar Cte"
                    >mdi-cancel</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-flex>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarListaCte"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnPrepareMdfe"
            style="background-color: #757575; color: white"
            >Preparar MDFE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCte" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cte</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-tabs v-model="tabCte" background-color="#808080" dark>
            <v-tab href="#dadosCTE">Dados Cte</v-tab>
            <v-tab href="#notas">Notas Fiscais</v-tab>

            <v-tab-item value="dadosCTE">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="CNPJ Remetente"
                        v-model="itemCte.cpfCnpjRemetenteFormatado"
                        v-mask="'##.###.###/####-##'"
                        maxlength="18"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        maxlength="44"
                        label="Remetente"
                        v-model="itemCte.razaoSocialRemetente"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="CNPJ Destinatario"
                        v-model="itemCte.cpfCnpjDestinatarioFormatado"
                        v-mask="'##.###.###/####-##'"
                        maxlength="18"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        maxlength="44"
                        label="Destinatario"
                        v-model="itemCte.razaoSocialDestinatario"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3">
                      <v-select
                        ref="modalidadeInput"
                        label="Tomador"
                        v-model="itemCte.modalidade"
                        :items="modalidadeItens"
                        item-text="description"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Placa"
                        maxlength="10"
                        v-model="itemCte.placa"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="Produto Predominante"
                        maxlength="30"
                        v-model="itemCte.produtoPredominante"
                        @input="
                          itemCte.produtoPredominante = $event.toUpperCase()
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        label="RNTRC"
                        maxlength="8"
                        v-model="itemCte.rntrc"
                        @input="itemCte.rntrc = $event.toUpperCase()"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field
                        ref="qtdeCargaCteFormatadoInput"
                        label="Qtde Carga"
                        v-model="itemCte.qtdeCargaFormatado"
                        v-money="{ precision: 3, prefix: '' }"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        ref="pesoCargaCteFormatadoInput"
                        label="Peso Carga"
                        v-model="itemCte.pesoCargaFormatado"
                        v-money="{ precision: 3, prefix: '' }"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        ref="valorFreteCteFormatadoInput"
                        label="Valor Frete R$"
                        v-model="itemCte.valorFreteFormatado"
                        v-money="{ precision: 2, prefix: '' }"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        ref="valorCargaCteInput"
                        label="Total R$"
                        v-model="itemCte.valorCargaFormatado"
                        v-money="{ precision: 2, prefix: '' }"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        label="Observação"
                        maxlength="200"
                        v-model="itemCte.observacao"
                        @input="itemCte.observacao = $event.toUpperCase()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- 
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br /> -->
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="notas">
              <v-card>
                <v-card-text>
                  <v-skeleton-loader
                    v-if="showLoaderListaCte"
                    type="table"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    v-if="showLoaderListaNotaFiscais"
                    type="table"
                  ></v-skeleton-loader>
                  <v-flex class="p-4 white" color="white">
                    <template class="p-5">
                      <v-data-table
                        :headers="headersNotaFiscalCte"
                        :items="listaNotaFiscais"
                        v-model="selectedNotaFiscal"
                        item-key="chave"
                        class="elevation-1"
                        :single-select="singleSelect"
                        hide-default-footer
                      >
                      </v-data-table>
                    </template>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarCte"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarCte"
            style="background-color: #757575; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogListaMdfe">
      <v-card>
        <v-card-title>
          <h4>MDFE</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-toolbar
          style="
            background-color: #808080;
            margin-left: 17px;
            margin-right: 17px;
          "
          class="elevation-1"
        >
          <v-toolbar-title>
            <h5 style="color: white">Lista de Mdfes</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #757575; margin-left: 5px"
            @click="importarNotaFiscal()"
          >
            <v-icon left> mdi-check-circle-outline</v-icon>
            <b>Validar Mdfes</b>
          </v-btn> -->
        </v-toolbar>

        <v-skeleton-loader
          v-if="showLoaderListaMdfe"
          type="table"
        ></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headersMdfe"
              :items="listaMdfes"
              v-model="selectedMdfe"
              item-key="chave"
              class="elevation-1"
              :single-select="singleSelect"
              hide-default-footer
            >
              <template v-slot:item.status="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColorStatus(item.status)"
                  dark
                  >{{ getStatusText(item.status) }}
                </v-chip>
              </template>

              <template v-slot:item.acoes="{ item }">
                <v-btn @click="editarMdfe(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Editar Mdfe"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn @click="validarMdfe(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Validar Mdfe"
                    >mdi-check-circle</v-icon
                  >
                </v-btn>
                <v-btn
                  @click="
                    downloadMdfe(item, item.caminhoMdfe, item.numeroMdfe, 'pdf')
                  "
                  small
                >
                  <v-icon :style="{ color: '#757575' }" title="Download Mdfe"
                    >mdi-download</v-icon
                  >
                </v-btn>
                <v-btn @click="cancelarMdfe(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Cancelar Mdfe"
                    >mdi-cancel</v-icon
                  >
                </v-btn>
                <v-btn @click="listarMotoristas(item)" small>
                  <v-icon
                    :style="{ color: '#757575' }"
                    title="Cadastro de Motorista"
                    >mdi-steering</v-icon
                  >
                </v-btn>
                <v-btn @click="listarVeiculos(item)" small>
                  <v-icon
                    :style="{ color: '#757575' }"
                    title="Cadastro de Veículo"
                    >mdi-car</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-flex>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarListaMdfe"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnFinalizarMdfe"
            style="background-color: #757575; color: white"
            >Finalizar Processo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMdfe" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Edição MDFE</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-tabs v-model="tabMdfe" background-color="#808080" dark>
            <v-tab href="#dadosMDFE">Dados Mdfe</v-tab>
            <v-tab href="#munDesc">Municípios Descarregamento</v-tab>
            <v-tab href="#percursos">Percursos</v-tab>

            <v-tab-item value="dadosMDFE">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="2">
                      <v-text-field
                        ref="pesoCargaMdfeFormatadoInput"
                        label="Peso Carga"
                        v-model="itemMdfe.pesoCargaFormatado"
                        v-money="{ precision: 3, prefix: '' }"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        ref="valorCargaMdfeInput"
                        label="Total R$"
                        v-model="itemMdfe.valorCargaFormatado"
                        v-money="{ precision: 2, prefix: '' }"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        ref="munCarrCepInput"
                        label="CEP Carreg."
                        v-model="itemMdfe.munCarrCep"
                        v-mask="'#####-###'"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        ref="munCarrUfInput"
                        v-model="itemMdfe.munCarrUf"
                        :items="estados"
                        label="UF Carreg."
                        item-text="sigla"
                        item-value="sigla"
                        @input="loadMunicipiosCarregamento"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select
                        ref="munCarrNomeInput"
                        v-model="itemMdfe.munCarrNome"
                        :items="municipiosCarregamento"
                        label="Município Carregamento"
                        item-text="nome"
                        item-value="nome"
                        @input="atualizarCodigoIbgeMunCarr"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="2">
                      <v-text-field
                        ref="munIniCepInput"
                        label="CEP Mun. Início"
                        v-model="itemMdfe.munIniCep"
                        v-mask="'#####-###'"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        ref="munIniUfInput"
                        v-model="itemMdfe.munIniUf"
                        :items="estados"
                        label="UF Mun. Início"
                        item-text="sigla"
                        item-value="sigla"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field
                        ref="munFimCepInput"
                        label="CEP Mun.Fim"
                        v-model="itemMdfe.munFimCep"
                        v-mask="'#####-###'"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-select
                        ref="munFimUfInput"
                        v-model="itemMdfe.munFimUf"
                        :items="estados"
                        label="UF Mun.Fim"
                        item-text="sigla"
                        item-value="sigla"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        ref="observacaoMdfeInput"
                        label="Observação"
                        v-model="itemMdfe.observacao"
                        @input="itemMdfe.observacao = $event.toUpperCase()"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        ref="prodPredNomeInput"
                        label="Produto Predominante"
                        v-model="itemMdfe.prodPredNome"
                        @input="itemMdfe.prodPredNome = $event.toUpperCase()"
                        :disabled="processoEmAndamento"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        ref="prodPredCeanInput"
                        label="cean"
                        v-model="itemMdfe.prodPredCean"
                        :disabled="processoEmAndamento"
                        maxlength="8"
                        v-numbers-only
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        ref="prodPredNcmInput"
                        label="NCM"
                        v-model="itemMdfe.prodPredNcm"
                        :disabled="processoEmAndamento"
                        maxlength="8"
                        v-numbers-only
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select
                        ref="idMotoristaInput"
                        v-model="itemMdfe.idMotorista"
                        :items="motoristas"
                        label="Motorista"
                        item-text="nome"
                        item-value="id"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        ref="idVeiculoInput"
                        v-model="itemMdfe.idVeiculo"
                        :items="veiculos"
                        label="Veículo"
                        item-text="tipo"
                        item-value="id"
                        :disabled="processoEmAndamento"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card-actions>
                <v-btn
                  color="error"
                  @click="clickBtnCancelarMdfe"
                  style="background-color: #757575; color: white"
                  >Fechar</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="clickBtnSalvarMdfe"
                  style="background-color: #757575; color: white"
                  >Salvar</v-btn
                >
              </v-card-actions>
            </v-tab-item>

            <v-tab-item value="munDesc">
              <v-card>
                <v-card-text>
                  <v-skeleton-loader
                    v-if="showLoaderListaMunDesc"
                    type="table"
                  ></v-skeleton-loader>
                  <v-flex class="p-4 white" color="white">
                    <template class="p-5">
                      <v-data-table
                        :headers="headersMunDesc"
                        :items="listaMunDesc"
                        v-model="selectedMunDesc"
                        item-key="chave"
                        class="elevation-1"
                        :single-select="singleSelect"
                        hide-default-footer
                      >
                        <template v-slot:item.acoes="{ item }">
                          <v-btn @click="editarMunDesc(item)" small>
                            <v-icon
                              :style="{ color: '#757575' }"
                              title="Editar Municipio Carregamento"
                              >mdi-pencil</v-icon
                            >
                          </v-btn>
                        </template>
                      </v-data-table>
                    </template>
                  </v-flex>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      @click="clickBtnCancelarListaMunDesc"
                      style="background-color: #757575; color: white"
                      >Fechar</v-btn
                    >
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="percursos">
              <v-card>
                <v-card-text>
                  <v-skeleton-loader
                    v-if="showLoaderListaPercurso"
                    type="table"
                  ></v-skeleton-loader>
                  <v-flex class="p-4 white" color="white">
                    <template class="p-5">
                      <v-data-table
                        :headers="headersPercurso"
                        :items="listaPercurso"
                        v-model="selectedPercurso"
                        item-key="chave"
                        class="elevation-1"
                        :single-select="singleSelect"
                        hide-default-footer
                      >
                        <template v-slot:item.acoes="{ item }">
                          <v-btn @click="deletarPercurso(item)" small>
                            <v-icon
                              :style="{ color: '#757575' }"
                              title="Deletar Percurso"
                              >mdi-delete</v-icon
                            >
                          </v-btn>
                        </template>
                      </v-data-table>
                    </template>
                  </v-flex>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      @click="clickBtnNovoPercurso"
                      style="background-color: #757575; color: white"
                      >Novo Percurso</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="clickBtnCancelarListaPerCurso"
                      style="background-color: #757575; color: white"
                      >Fechar</v-btn
                    >
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMunDesc" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Municipios Descarregamento</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Municipio"
                v-model="itemMunDesc.munNome"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                label="UF"
                v-model="itemMunDesc.uf"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="cepInput"
                label="CEP"
                v-model="itemMunDesc.cepFormatado"
                v-mask="'#####-###'"
                v-numbers-only
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarMunDesc"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarMunDesc"
            style="background-color: #757575; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPercurso" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Novo Percurso</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="2">
              <v-select
                ref="ufPercursoInput"
                v-model="itemPercurso.uf"
                :items="estados"
                label="UF"
                item-text="sigla"
                item-value="sigla"
                :disabled="processoEmAndamento"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarPercurso"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarPercurso"
            style="background-color: #757575; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogListaMotorista">
      <v-card>
        <v-card-title>
          <h4>Motorista</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-toolbar
          style="
            background-color: #808080;
            margin-left: 17px;
            margin-right: 17px;
          "
          class="elevation-1"
        >
          <v-toolbar-title>
            <h5 style="color: white">Lista de Motorista</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #757575; margin-left: 5px"
            @click="novoMotorista()"
          >
            <v-icon left> mdi-plus</v-icon>
            <b>Novo</b>
          </v-btn>
        </v-toolbar>

        <v-skeleton-loader
          v-if="showLoaderListaMotorista"
          type="table"
        ></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headersMotorista"
              :items="listaMotoristas"
              v-model="selectedMotorista"
              item-key="chave"
              class="elevation-1"
              :single-select="singleSelect"
              hide-default-footer
            >
              <template v-slot:item.acoes="{ item }">
                <v-btn @click="editarMotorista(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Editar Motorista"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn @click="deletarMotorista(item)" small>
                  <v-icon
                    :style="{ color: '#757575' }"
                    title="Remover motorista deste Tranportador"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-flex>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarListaMotorista"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMotorista" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastro de Motorista</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                ref="cpfMotoristaInput"
                label="CPF"
                v-model="itemMotorista.cpfFormatado"
                v-mask="'###.###.###-##'"
                maxlength="18"
                @blur="consultarCpf"
                :append-outer-icon="limparCampoIcon"
                @click:append-outer="limparDadosCompletoMotorista"
                :readonly="somenteLeituraCpf"
                :disabled="processoEmAndamento"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                ref="nomeMotoristaInput"
                maxlength="60"
                label="Nome"
                v-model="itemMotorista.nome"
                :disabled="processoEmAndamento"
                @input="itemMotorista.nome = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <label>
                <input type="checkbox" v-model="itemMotorista.ativo" />
                Ativo
              </label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarMotorista"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarMotorista"
            style="background-color: #757575; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogListaVeiculo">
      <v-card>
        <v-card-title>
          <h4>Veiculo</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-toolbar
          style="
            background-color: #808080;
            margin-left: 17px;
            margin-right: 17px;
          "
          class="elevation-1"
        >
          <v-toolbar-title>
            <h5 style="color: white">Lista de Veiculo</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            dark
            rounded
            color="white"
            style="background-color: white; color: #757575; margin-left: 5px"
            @click="novoVeiculo()"
          >
            <v-icon left> mdi-plus</v-icon>
            <b>Novo</b>
          </v-btn>
        </v-toolbar>

        <v-skeleton-loader
          v-if="showLoaderListaVeiculo"
          type="table"
        ></v-skeleton-loader>
        <v-flex class="p-4 white" color="white">
          <template class="p-5">
            <v-data-table
              :headers="headersVeiculo"
              :items="listaVeiculos"
              v-model="selectedVeiculo"
              item-key="chave"
              class="elevation-1"
              :single-select="singleSelect"
              hide-default-footer
            >
              <template v-slot:item.acoes="{ item }">
                <v-btn @click="editarVeiculo(item)" small>
                  <v-icon :style="{ color: '#757575' }" title="Editar Veiculo"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
                <v-btn @click="deletarVeiculo(item)" small>
                  <v-icon
                    :style="{ color: '#757575' }"
                    title="Remover veiculo deste Tranportador"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-flex>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarListaVeiculo"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVeiculo" max-width="800px">
      <v-card>
        <v-card-title>
          <h4>Cadastro de Veiculo</h4>
        </v-card-title>
        <v-divider></v-divider>
        <br />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                ref="placaInput"
                label="Placa"
                v-model="itemVeiculo.placa"
                maxlength="8"
                @blur="consultarPlaca"
                :append-outer-icon="limparCampoIcon"
                @click:append-outer="limparDadosCompletoVeiculo"
                :readonly="somenteLeituraPlaca"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.placa = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="renavamInput"
                maxlength="11"
                label="Renavam"
                v-model="itemVeiculo.renavam"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.renavam = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="taraInput"
                maxlength="6"
                label="Tara"
                v-model="itemVeiculo.tara"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.tara = $event.toUpperCase()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                ref="capKgInput"
                maxlength="6"
                label="Capacidade"
                v-model="itemVeiculo.capKg"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.capKg = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                ref="tipoRodadoInput"
                label="Tipo Rodado"
                v-model="itemVeiculo.tipoRodado"
                :items="listaTipoRodado"
                item-text="description"
                :disabled="processoEmAndamento"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                ref="tipoCarroceriaInput"
                label="Tipo Carroceira"
                v-model="itemVeiculo.tipoCarroceria"
                :items="listaTipoCarroceria"
                item-text="description"
                :disabled="processoEmAndamento"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                ref="tipoVeiculoInput"
                label="Tipo Veiculo"
                v-model="itemVeiculo.tipoVeiculo"
                :items="listaTipoVeiculo"
                item-text="description"
                :disabled="processoEmAndamento"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="rntrcInput"
                maxlength="8"
                label="RNTRC"
                v-model="itemVeiculo.rntrc"
                :disabled="processoEmAndamento"
                @input="itemVeiculo.rntrc = $event.toUpperCase()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="2">
              <v-select
                ref="ufLicenciamentoInput"
                v-model="itemVeiculo.ufLicenciamento"
                :items="estados"
                label="UF"
                item-text="sigla"
                item-value="sigla"
                :disabled="processoEmAndamento"
              ></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <label>
                <input type="checkbox" v-model="itemVeiculo.ativo" />
                Ativo
              </label>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            @click="clickBtnCancelarVeiculo"
            style="background-color: #757575; color: white"
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="clickBtnSalvarVeiculo"
            style="background-color: #757575; color: white"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCancelMdfe" max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline">Cancelar MDF-e</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="justificativaCancelamentoMDfe"
            label="Justificativa para cancelamento"
            type="text"
            :rules="[
              (v) => !!v || 'A justificativa é obrigatória',
              (v) => v.length >= 15 || 'Mínimo 15 caracteres',
            ]"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogCancelMdfe = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmarCancelamentoMdfe">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCancelCte" max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline">Cancelar CT-e</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="justificativaCancelamentoCte"
            label="Justificativa para cancelamento"
            type="text"
            :rules="[
              (v) => !!v || 'A justificativa é obrigatória',
              (v) => v.length >= 15 || 'Mínimo 15 caracteres',
            ]"
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogCancelCte = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmarCancelamentoCte">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseWidget from "../components/BaseWidget";
import ShowMessage from "../components/ShowMessage";
import Transportador from "../service/transportador";
import EmissaoViagem from "../service/emissaoViagem";
import Logradouro from "../service/logradouros";
import Movimentacao from "../service/movimentacao";
import Mdfe from "../service/mdfe";
import Cte from "../service/cte";
import { mapActions, mapMutations } from "vuex";
// import { timestamp } from "@vueuse/core";

export default {
  name: "EmissaoViagem",
  components: {
    BaseWidget,
    ShowMessage,
  },
  directives: {
    "numbers-only": {
      bind(el) {
        el.addEventListener("input", function (event) {
          const inputValue = event.target.value;
          event.target.value = inputValue.replace(/\D/g, "");
        });
      },
    },
  },
  data() {
    return {
      mensagem: "",
      dialogCadastro: false,
      dialogListaNotaFiscal: false,
      dialogNotaFiscal: false,
      dialogListaCte: false,
      dialogCte: false,
      dialogListaMdfe: false,
      dialogMdfe: false,
      dialogMunDesc: false,
      dialogPercurso: false,
      dialogImportarNotaFiscal: false,
      dialogListaMotorista: false,
      dialogListaVeiculo: false,
      dialogMotorista: false,
      dialogVeiculo: false,
      dialogCancelMdfe: false,
      dialogCancelCte: false,
      justificativaCancelamentoMDfe: "",
      justificativaCancelamentoCte: "",
      somenteLeituraCpf: false,
      somenteLeituraPlaca: false,
      xmlNfeFile: null,
      xmlNfeConteudo: "",
      tabNotaFiscal: "dadosNota",
      tabCte: "dadosCTE",
      tabMdfe: "dadosMDFE",
      limparCampoIcon: "mdi-close-circle",
      somenteLeituraCnpjRemetente: false,
      somenteLeituracpfCnpjDestinatario: false,
      processoEmAndamento: false,
      qtdeNotasFiscais: 0,
      totalNotasFiscaisFormatado: "",
      headers: [
        { align: "start", sortable: false },
        { text: "Código", align: "left", value: "id" },
        { text: "Descrição", align: "left", value: "descricao" },
        { text: "Transportador", align: "left", value: "nomeFantasia" },
        { text: "Data/Hora", align: "center", value: "dataHora" },
        { text: "Gerar MDF-e", align: "center", value: "mdfe" },
        { text: "Preparar Cte", align: "center", value: "prepararCte" },
        { text: "Preparar Mdfe", align: "center", value: "prepararMdfe" },
        { text: "Concluido", align: "center", value: "validado" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      headersNotaFiscal: [
        { align: "start", sortable: false },
        { text: "Chave", align: "center", value: "chave" },
        { text: "Remetente", align: "left", value: "razaoSocialRemetente" },
        {
          text: "Destinatario",
          align: "left",
          value: "razaoSocialDestinatario",
        },
        {
          text: "Valor Carga",
          align: "left",
          value: "valorCargaFormatadoGrid",
        },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      headersNotaFiscalCte: [
        { align: "start", sortable: false },
        { text: "Chave", align: "center", value: "chave" },
        { text: "Valor Total", align: "left", value: "valorNotaFormatadoGrid" },
      ],
      headersCte: [
        { align: "start", sortable: false },
        { text: "ID", align: "center", value: "id" },
        { text: "Remetente", align: "left", value: "razaoSocialRemetente" },
        {
          text: "Destinatario",
          align: "left",
          value: "razaoSocialDestinatario",
        },
        {
          text: "Valor Carga",
          align: "left",
          value: "valorCargaFormatadoGrid",
        },
        { text: "Status", align: "center", value: "status" },
        { text: "Numero CTE", align: "center", value: "numeroCte" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      headersMdfe: [
        { align: "start", sortable: false },
        { text: "ID", align: "center", value: "id" },
        {
          text: "CNPJ Transportador",
          align: "left",
          value: "cnpjTranspFormatado",
        },
        { text: "Peso Carga", align: "left", value: "pesoCargaFormatado" },
        {
          text: "Valor Carga",
          align: "left",
          value: "valorCargaFormatadoGrid",
        },
        { text: "Status", align: "center", value: "status" },
        { text: "Numero MDFE", align: "center", value: "numeroMdfe" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      headersMunDesc: [
        { align: "start", sortable: false },
        { text: "Cidade", align: "left", value: "munNome" },
        { text: "CEP", align: "center", value: "cep" },
        { text: "UF", align: "center", value: "uf" },
        { text: "Código IBGE", align: "center", value: "codigoIbge" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      headersPercurso: [
        { align: "start", sortable: false },
        { text: "UF", align: "center", value: "uf" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      modalidadeItens: [
        { value: 0, description: "REMETENTE" },
        { value: 1, description: "EXPEDIDOR" },
        { value: 2, description: "RECEBEDOR" },
        { value: 3, description: "DESTINATARIO" },
        //{ value: 4, description: "OUTROS" },
      ],
      headersMotorista: [
        { align: "start", sortable: false },
        { text: "Código", align: "left", value: "id" },
        { text: "Nome", align: "left", value: "nome" },
        { text: "CPF", value: "cpfFormatado", align: "left" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      headersVeiculo: [
        { align: "start", sortable: false },
        { text: "Código", align: "center", value: "id" },
        { text: "Placa", align: "center", value: "placa" },
        { text: "Renavam", align: "center", value: "renavam" },
        { text: "Ações", value: "acoes", sortable: false },
      ],
      estados: [],
      motoristas: [],
      veiculos: [],
      participantes: [],
      municipiosRemetente: [],
      municipiosDestinatario: [],
      municipiosCarregamento: [],
      selectedUF: null,
      selectedMunicipio: null,
      listaEmissaoViagem: [],
      item: {
        id: 0,
        descricao: "",
        idParticipante: 0,
        razaoSocial: "",
        nomeFantasia: "",
        cpfCnpj: "",
        idUsuario: localStorage.getItem("id"),
        dataHora: "",
        qtdeMdfe: 0,
        qtdeCte: 0,
        prepararCte: false,
        prepararMdfe: false,
        validado: false,
        mdfe: false,
      },
      listaNotaFiscais: [],
      itemNotaFiscal: {
        id: 0,
        idEmissaoViagem: 0,
        chave: "",
        qtdeCarga: 0,
        pesoCarga: 0,
        valorCarga: 0,
        qtdeCargaFormatado: 0,
        pesoCargaFormatado: 0,
        valorCargaFormatado: 0,
        valorCargaFormatadoGrid: "",
        idRemetente: 0,
        razaoSocialRemetente: "",
        nomeFantasiaRemetente: "",
        cpfCnpjRemetente: "",
        cpfCnpjRemetenteFormatado: "",
        inscricaoEstadualRemetente: "",
        emailRemetente: "",
        logradouroRemetente: "",
        numeroRemetente: "",
        complementoRemetente: "",
        bairroRemetente: "",
        codigoIbgeRemetente: "",
        municipioRemetente: "",
        cepRemetente: "",
        cepRemetenteFormatado: "",
        ufRemetente: "",
        foneRemetente: "",
        foneRemetenteFormatado: "",
        observacaoRemetente: "",
        idDestinatario: 0,
        razaoSocialDestinatario: "",
        nomeFantasiaDestinatario: "",
        cpfCnpjDestinatario: "",
        cpfCnpjDestinatarioFormatado: "",
        inscricaoEstadualDestinatario: "",
        emailDestinatario: "",
        logradouroDestinatario: "",
        numeroDestinatario: "",
        complementoDestinatario: "",
        bairroDestinatario: "",
        codigoIbgeDestinatario: "",
        municipioDestinatario: "",
        cepDestinatario: "",
        cepDestinatarioFormatado: "",
        ufDestinatario: "",
        foneDestinatario: "",
        foneDestinatarioFormatado: "",
        observacaoDestinatario: "",
      },
      listaCtes: [],
      itemCte: {
        id: 0,
        idEmissaoViagem: 0,
        qtdeCarga: 0,
        pesoCarga: 0,
        valorCarga: 0,
        valorFrete: 0,
        qtdeCargaFormatado: 0,
        pesoCargaFormatado: 0,
        valorCargaFormatado: 0,
        valorCargaFormatadoGird: 0,
        valorFreteFormatado: 0,
        idRemetente: 0,
        razaoSocialRemetente: "",
        cpfCnpjRemetente: "",
        cpfCnpjRemetenteFormatado: "",
        idDestinatario: 0,
        razaoSocialDestinatario: "",
        cpfCnpjDestinatario: "",
        cpfCnpjDestinatarioFormatado: "",
        modalidade: 3,
        placa: "",
        produtoPredominante: "",
        rntrc: "",
        observacao: "",
        status: "",
        numeroCte: "",
        numeroProtocolo: "",
        caminhoCte: "",
      },
      listaMdfes: [],
      itemMdfe: {
        id: 0,
        idEmissaoViagem: 0,
        pesoCarga: 0,
        valorCarga: 0,
        pesoCargaFormatado: 0,
        pesoCargaFormatadoGrid: 0,
        valorCargaFormatado: 0,
        valorCargaFormatadoGird: 0,
        status: "",
        numeroMdfe: "",
        caminhoMdfe: "",
        cnpjAutDownload: "",
        cnpjContmodal: "",
        cnpjTransp: "",
        unidadeMedida: "",
        munCarrCep: "",
        munCarrCodigoIbge: 0,
        munCarrNome: "",
        munCarrUf: "",
        munIniCep: "",
        munIniUf: "",
        munFimCep: "",
        munFimUf: "",
        observacao: "",
        prodPredCean: "",
        prodPredNcm: "",
        prodPredNome: "",
        prodPredTipoCarga: "",
        idVeiculo: 0,
        idMotorista: 0,
      },
      listaMunDesc: [],
      itemMunDesc: {
        id: 0,
        idEmissaoViagemMdfe: 0,
        munNome: "",
        cep: "",
        cepFormatado: "",
        uf: "",
        codigoIbge: 0,
      },
      listaPercurso: [],
      itemPercurso: {
        id: 0,
        idMdfeAvulso: 0,
        uf: "",
      },
      itemAtualizarCte: {
        produtoPredominante: "",
        valorFrete: 0,
        valorFreteFormatado: 0,
        observacao: "",
      },
      listaMotoristas: [],
      itemMotorista: {
        id: 0,
        idParticipante: 0,
        idMotorista: 0,
        nome: "",
        cpf: "",
        cpfFormatado: "",
        ativo: true,
      },
      listaVeiculos: [],
      itemVeiculo: {
        id: 0,
        idParticipante: 0,
        idVeiculo: 0,
        placa: "",
        tipoVeiculo: 0,
        renavam: "",
        tara: "",
        capKg: "",
        ufLicenciamento: "",
        rntrc: "",
        tipoRodado: 0,
        tipoCarroceria: 0,
        ativo: true,
      },
      selected: [],
      selectedNotaFiscal: [],
      selectedCte: [],
      selectedMdfe: [],
      selectedMunDesc: [],
      selectedPercurso: [],
      selectedMotorista: [],
      selectedVeiculo: [],
      singleSelect: false,
      listaPesquisa: [
        { value: "ID", description: "ID" },
        { value: "DESCRICAO", description: "DESCRICAO" },
        { value: "ULTIMOS_5", description: "ULTIMOS 5" },
        { value: "ULTIMOS_10", description: "ULTIMOS 10" },
        { value: "ULTIMOS_15", description: "ULTIMOS 15" },
      ],
      listaStatus: [
        { value: "TODOS", description: "TODOS" },
        { value: "VALIADO", description: "VALIADO" },
        { value: "NAO_VALIDADO", description: "NAO VALIDADO" },
      ],
      listaTipoVeiculo: [
        { value: 0, description: "PRINCIPAL" },
        { value: 1, description: "REBOQUE" },
      ],
      listaTipoRodado: [
        { value: 1, description: "TRUCK" },
        { value: 2, description: "TOCO" },
        { value: 3, description: "CAVALO" },
        { value: 4, description: "VAN" },
        { value: 5, description: "UTILITARIO" },
        { value: 6, description: "OUTROS" },
      ],
      listaTipoCarroceria: [
        { value: 1, description: "NAO APLICAVEL " },
        { value: 2, description: "ABERTA" },
        { value: 3, description: "FECHADA/BAU" },
        { value: 4, description: "GRANELERA" },
        { value: 5, description: "PORTA CONTAINER" },
        { value: 6, description: "SIDER" },
      ],
      pesquisaSelecionado: "",
      tfPesquisa: "",
      statusSelecionado: "",
      selecaoFiltro: {
        campo: "",
        valor: "",
      },
      showLoader: false,
      showLoaderListaNotaFiscais: false,
      showLoaderListaCte: false,
      showLoaderListaMdfe: false,
      showLoaderListaMunDesc: false,
      showLoaderListaPercurso: false,
      showLoaderListaMotorista: false,
      showLoaderListaVeiculo: false,
      filtroRelatorio: "",
      getColorValidado(validado) {
        if (validado == true) {
          return "#068206";
        } else {
          return "#F44336";
        }
      },
      getColorStatus(status) {
        switch (status) {
          case "EM_PREPARACAO":
            return "#757575";
          case "NAO_ENVIADO":
            return "#757575";
          case "ERRO":
            return "#F44336";
          case "AUTORIZADO":
            return "#068206";
          case "CANCELADO":
            return "#F44336";
          default:
            return "757575";
        }
      },
      getColorPrepararCte(prepararCte) {
        if (prepararCte == true) {
          return "#068206";
        } else {
          return "#F44336";
        }
      },
      getColorPrepararMdfe(prepararMdfe) {
        if (prepararMdfe == true) {
          return "#068206";
        } else {
          return "#F44336";
        }
      },
      getColorMdfe(mdfe) {
        if (mdfe == true) {
          return "#068206";
        } else {
          return "#F44336";
        }
      },
      singleExpand: true,
    };
  },

  computed: {
    itens() {
      return {
        selectedUF: this.selectedUF,
        selectedCidade: this.selectedCidade,
      };
    },

    pesquisaSelecionadoDescription() {
      const selectedValue = this.pesquisaSelecionado;
      const selectedOption = this.listaPesquisa.find(
        (item) => item.value === selectedValue
      );
      return selectedOption ? selectedOption.description : "";
    },
  },

  mounted() {
    this.statusSelecionado = "TODOS";
  },

  created() {
    this.loadParticipantes();
  },

  methods: {
    ...mapActions(["snackSuccess", "snackError"]),
    ...mapMutations(["setLoading"]),

    filtrar() {
      this.selected = [];
      this.showLoader = true;

      const tipoFiltro = this.pesquisaSelecionado;
      const valorFiltro = this.tfPesquisa;
      const status = this.statusSelecionado;
      const idUsuario = parseInt(localStorage.getItem("id"), 10);

      if (tipoFiltro == "") {
        this.$refs.message.showMsg("Atenção!", "Informe o tipo do filtro");
        this.showLoader = false;
        return;
      }

      if (
        tipoFiltro != "ULTIMOS_5" &&
        tipoFiltro != "ULTIMOS_10" &&
        tipoFiltro != "ULTIMOS_15" &&
        !valorFiltro
      ) {
        this.$refs.message.showMsg("Atenção!", "Informe o valor do filtro");
        this.showLoader = false;
        return;
      }

      if (status < 0) {
        this.$refs.message.showMsg("Atenção!", "Informe a Situação");
        this.showLoader = false;
        return;
      }

      const parsedValue = parseInt(valorFiltro, 10);

      if (
        this.pesquisaSelecionado === "ID" &&
        isNaN(parsedValue) &&
        !Number.isInteger(parsedValue)
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Para este de tipo de pesquisa é necessário digitar valores inteiro."
        );
        this.showLoader = false;
        return;
      }

      EmissaoViagem.getEmissaoViagem(tipoFiltro, valorFiltro, status, idUsuario)
        .then((response) => {
          this.listaEmissaoViagem = response.data.map((item) => {
            return {
              id: item.id,
              descricao: item.descricao,
              idUsuario: item.idUsuario,
              dataHora: this.convertDate(item.dataHora),
              qtdeMdfe: item.qtdeMdfe,
              qtdeCte: item.qtdeCte,
              mdfe: item.mdfe,
              prepararCte: item.prepararCte,
              prepararMdfe: item.prepararMdfe,
              validado: item.validado,
              idParticipante: item.participante.id,
              razaoSocial: item.participante.razaoSocial,
              nomeFantasia:
                item.participante.id + " - " + item.participante.nomeFantasia,
              cpfCnpj: item.participante.cpfCnpj,
              acoes: [
                {
                  icon: "mdi-pencil",
                  callback: () => this.editarEmissaoViagem(item),
                },
                {
                  icon: "mdi-receipt",
                  callback: () => this.listarNotaFiscal(item),
                },
                {
                  icon: "mdi-menu",
                  callback: () => this.listarCte(item),
                },
                {
                  icon: "mdi-menu",
                  callback: () => this.listaMdfes(item),
                },
                // {
                //   icon: "mdi-delete",
                //   callback: () => this.deleteNotaFiscal(item),
                // },
              ],
            };
          });
          this.showLoader = false;
        })
        .catch((error) => {
          this.listaEmissaoViagem = [];
          this.showLoader = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar a Emissao Viagem: " +
                error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar a Emissao Viagem: " + error.message
            );
          }
        });
    },

    carregarNotaFiscais(idEmissaoViagem) {
      this.selectedNotaFiscal = [];
      this.showLoaderListaNotaFiscais = true;
      this.listaNotaFiscais = [];
      this.qtdeNotasFiscais = 0;
      this.totalNotasFiscaisFormatado = 0;

      EmissaoViagem.getNotaFiscais(idEmissaoViagem)
        .then((response) => {
          this.listaNotaFiscais = response.data.map((itemNotaFiscal) => {
            const cpfCnpjDestinatarioFormatado = this.formatarCpfCnpj(
              itemNotaFiscal.destinatario.cpfCnpj
            );
            return {
              id: itemNotaFiscal.id,
              idEmissaoViagem: itemNotaFiscal.emissaoViagem.id,
              chave: itemNotaFiscal.chave,
              valorCarga: itemNotaFiscal.valorCarga,
              qtdeCargaFormatado: this.formatarCasasDecimais(
                itemNotaFiscal.qtdeCarga,
                3
              ),
              pesoCargaFormatado: this.formatarCasasDecimais(
                itemNotaFiscal.pesoCarga,
                3
              ),
              valorCargaFormatado: this.formatarCasasDecimais(
                itemNotaFiscal.valorCarga,
                2
              ),
              valorCargaFormatadoGrid: itemNotaFiscal.valorCarga.toLocaleString(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              ),
              idRemetente: itemNotaFiscal.remetente.id,
              razaoSocialRemetente: itemNotaFiscal.remetente.razaoSocial,
              nomeFantasiaRemetente: itemNotaFiscal.remetente.nomeFantasia,
              cpfCnpjRemetenteFormatado: itemNotaFiscal.remetente.cpfCnpj,
              inscricaoEstadualRemetente:
                itemNotaFiscal.remetente.inscricaoEstadual,
              emailRemetente: itemNotaFiscal.remetente.email,
              logradouroRemetente: itemNotaFiscal.remetente.logradouro,
              numeroRemetente: itemNotaFiscal.remetente.numero,
              complementoRemetente: itemNotaFiscal.remetente.complemento,
              bairroRemetente: itemNotaFiscal.remetente.bairro,
              codigoIbgeRemetente: itemNotaFiscal.remetente.codigoIbge,
              municipioRemetente: itemNotaFiscal.remetente.municipio,
              cepRemetenteFormatado: itemNotaFiscal.remetente.cep,
              ufRemetente: itemNotaFiscal.remetente.uf,
              foneRemetenteFormatado: itemNotaFiscal.remetente.fone,
              observacaoRemetente: itemNotaFiscal.remetente.observacao,
              idDestinatario: itemNotaFiscal.destinatario.id,
              razaoSocialDestinatario: itemNotaFiscal.destinatario.razaoSocial,
              nomeFantasiaDestinatario:
                itemNotaFiscal.destinatario.nomeFantasia,
              cpfCnpjDestinatario: itemNotaFiscal.remetente.cpfCnpj,
              cpfCnpjDestinatarioFormatado: cpfCnpjDestinatarioFormatado,
              inscricaoEstadualDestinatario:
                itemNotaFiscal.destinatario.inscricaoEstadual,
              emailDestinatario: itemNotaFiscal.destinatario.email,
              logradouroDestinatario: itemNotaFiscal.destinatario.logradouro,
              numeroDestinatario: itemNotaFiscal.destinatario.numero,
              complementoDestinatario: itemNotaFiscal.destinatario.complemento,
              bairroDestinatario: itemNotaFiscal.destinatario.bairro,
              codigoIbgeDestinatario: itemNotaFiscal.destinatario.codigoIbge,
              municipioDestinatario: itemNotaFiscal.destinatario.municipio,
              cepDestinatarioFormatado: itemNotaFiscal.destinatario.cep,
              ufDestinatario: itemNotaFiscal.destinatario.uf,
              foneDestinatarioFormatado: itemNotaFiscal.destinatario.fone,
              observacaoDestinatario: itemNotaFiscal.destinatario.observacao,
              acoes: [
                {
                  icon: "mdi-pencil",
                  callback: () => this.editarNotaFiscal(itemNotaFiscal),
                },
                {
                  icon: "mdi-delete",
                  callback: () => this.deletarNotaFiscal(itemNotaFiscal),
                },
              ],
            };
          });

          this.qtdeNotasFiscais = this.listaNotaFiscais.length;

          let somaValores = 0;
          for (let i = 0; i < this.listaNotaFiscais.length; i++) {
            const itemNotaFiscal = this.listaNotaFiscais[i];

            somaValores += itemNotaFiscal.valorCarga;
          }

          (this.totalNotasFiscaisFormatado = somaValores.toLocaleString(
            "pt-BR",
            {
              style: "currency",
              currency: "BRL",
            }
          )),
            (this.showLoaderListaNotaFiscais = false);
        })
        .catch((error) => {
          this.listaNotaFiscais = [];
          this.showLoaderListaNotaFiscais = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar as Notas Fiscais: " +
                error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar as Notas Fiscais: " + error.message
            );
          }
        });
    },

    carregarNotaFiscaisCte(idEmissaoViagem, idRemetente, idDestinatario) {
      this.showLoaderListaNotaFiscais = true;
      this.listaNotaFiscais = [];

      EmissaoViagem.getNotaFiscaisCte(
        idEmissaoViagem,
        idRemetente,
        idDestinatario
      )
        .then((response) => {
          this.listaNotaFiscais = response.data.map((itemNotaFiscal) => {
            const cpfCnpjDestinatarioFormatado = this.formatarCpfCnpj(
              itemNotaFiscal.destinatario.cpfCnpj
            );
            return {
              id: itemNotaFiscal.id,
              idEmissaoViagem: itemNotaFiscal.emissaoViagem.id,
              chave: itemNotaFiscal.chave,
              valorCarga: itemNotaFiscal.valorCarga,
              qtdeCargaFormatado: this.formatarCasasDecimais(
                itemNotaFiscal.qtdeCarga,
                3
              ),
              pesoCargaFormatado: this.formatarCasasDecimais(
                itemNotaFiscal.pesoCarga,
                3
              ),
              valorCargaFormatado: this.formatarCasasDecimais(
                itemNotaFiscal.valorCarga,
                2
              ),
              valorNota: itemNotaFiscal.valorCarga,
              valorNotaFormatado: this.formatarCasasDecimais(
                itemNotaFiscal.valorCarga,
                2
              ),
              valorNotaFormatadoGrid: itemNotaFiscal.valorCarga.toLocaleString(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              ),
              idRemetente: itemNotaFiscal.remetente.id,
              razaoSocialRemetente: itemNotaFiscal.remetente.razaoSocial,
              nomeFantasiaRemetente: itemNotaFiscal.remetente.nomeFantasia,
              cpfCnpjRemetenteFormatado: itemNotaFiscal.remetente.cpfCnpj,
              inscricaoEstadualRemetente:
                itemNotaFiscal.remetente.inscricaoEstadual,
              emailRemetente: itemNotaFiscal.remetente.email,
              logradouroRemetente: itemNotaFiscal.remetente.logradouro,
              numeroRemetente: itemNotaFiscal.remetente.numero,
              complementoRemetente: itemNotaFiscal.remetente.complemento,
              bairroRemetente: itemNotaFiscal.remetente.bairro,
              codigoIbgeRemetente: itemNotaFiscal.remetente.codigoIbge,
              municipioRemetente: itemNotaFiscal.remetente.municipio,
              cepRemetenteFormatado: itemNotaFiscal.remetente.cep,
              ufRemetente: itemNotaFiscal.remetente.uf,
              foneRemetenteFormatado: itemNotaFiscal.remetente.fone,
              observacaoRemetente: itemNotaFiscal.remetente.observacao,
              idDestinatario: itemNotaFiscal.destinatario.id,
              razaoSocialDestinatario: itemNotaFiscal.destinatario.razaoSocial,
              nomeFantasiaDestinatario:
                itemNotaFiscal.destinatario.nomeFantasia,
              cpfCnpjDestinatario: itemNotaFiscal.remetente.cpfCnpj,
              cpfCnpjDestinatarioFormatado: cpfCnpjDestinatarioFormatado,
              inscricaoEstadualDestinatario:
                itemNotaFiscal.destinatario.inscricaoEstadual,
              emailDestinatario: itemNotaFiscal.destinatario.email,
              logradouroDestinatario: itemNotaFiscal.destinatario.logradouro,
              numeroDestinatario: itemNotaFiscal.destinatario.numero,
              complementoDestinatario: itemNotaFiscal.destinatario.complemento,
              bairroDestinatario: itemNotaFiscal.destinatario.bairro,
              codigoIbgeDestinatario: itemNotaFiscal.destinatario.codigoIbge,
              municipioDestinatario: itemNotaFiscal.destinatario.municipio,
              cepDestinatarioFormatado: itemNotaFiscal.destinatario.cep,
              ufDestinatario: itemNotaFiscal.destinatario.uf,
              foneDestinatarioFormatado: itemNotaFiscal.destinatario.fone,
              observacaoDestinatario: itemNotaFiscal.destinatario.observacao,
            };
          });

          this.showLoaderListaNotaFiscais = false;
        })
        .catch((error) => {
          this.listaNotaFiscais = [];
          this.showLoaderListaNotaFiscais = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar as Notas Fiscais CTE: " +
                error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar as Notas Fiscais CTE: " + error.message
            );
          }
        });
    },

    carregarCtes(idEmissaoViagem) {
      this.selectedCte = [];
      this.showLoaderListaCte = true;
      this.listaCtes = [];

      EmissaoViagem.getCtes(idEmissaoViagem)
        .then((response) => {
          this.listaCtes = response.data.map((cte) => {
            return {
              id: cte.id,
              idEmissaoViagem: cte.emissaoViagem.id,
              qtdeCargaFormatado: this.formatarCasasDecimais(cte.qtdeCarga, 3),
              pesoCargaFormatado: this.formatarCasasDecimais(cte.pesoCarga, 3),
              valorFreteFormatado: this.formatarCasasDecimais(
                cte.valorFrete,
                2
              ),
              valorCarga: this.formatarCasasDecimais(cte.valorCarga, 2),
              valorCargaFormatado: this.formatarCasasDecimais(
                cte.valorCarga,
                2
              ),
              valorCargaFormatadoGrid: cte.valorCarga.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              idRemetente: cte.remetente.id,
              razaoSocialRemetente: cte.remetente.razaoSocial,
              cpfCnpjRemetente: cte.remetente.cpfCnpj,
              cpfCnpjRemetenteFormatado: this.formatarCpfCnpj(
                cte.remetente.cpfCnpj
              ),
              idDestinatario: cte.destinatario.id,
              razaoSocialDestinatario: cte.destinatario.razaoSocial,
              cpfCnpjDestinatario: cte.destinatario.cpfCnpj,
              cpfCnpjDestinatarioFormatado: this.formatarCpfCnpj(
                cte.destinatario.cpfCnpj
              ),
              modalidade: cte.modalidade,
              placa: cte.placa,
              rntrc: cte.rntrc,
              produtoPredominante: cte.produtoPredominante,
              observacao: cte.observacao,
              status: cte.status,
              numeroCte: cte.numeroCte,
              numeroProtocolo: cte.numeroProtocolo,
              caminhoCte: cte.caminhoCte,
              acoes: [
                {
                  icon: "mdi-pencil",
                  callback: () => this.editarCte(cte),
                },
                {
                  icon: "mdi-delete",
                  callback: () => this.validarCte(cte),
                },
                {
                  icon: "mdi-donwload",
                  callback: () => this.downloadCte(cte),
                },
                {
                  icon: "mdi-cancel",
                  callback: () => this.cancelarCte(cte),
                },
              ],
            };
          });

          this.showLoaderListaCte = false;
        })
        .catch((error) => {
          this.listaCtes = [];
          this.showLoaderListaCte = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Ctes: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Ctes: " + error.message
            );
          }
        });
    },

    carregarMdfes(idEmissaoViagem) {
      this.selectedCte = [];
      this.showLoaderListaMdfe = true;
      this.listaMdfes = [];

      EmissaoViagem.getMdfes(idEmissaoViagem)
        .then((response) => {
          this.listaMdfes = response.data.map((mdfe) => {
            return {
              id: mdfe.id,
              idEmissaoViagem: mdfe.emissaoViagem.id,
              pesoCarga: mdfe.pesoCarga,
              pesoCargaFormatado: this.formatarCasasDecimais(mdfe.pesoCarga, 3),
              valorCarga: mdfe.valorCarga,
              valorCargaFormatado: this.formatarCasasDecimais(
                mdfe.valorCarga,
                2
              ),
              valorCargaFormatadoGrid: mdfe.valorCarga.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
              cnpjAutDownload: mdfe.cnpjAutDownload,
              cnpjContmodal: mdfe.cnpjContmodal,
              cnpjTransp: mdfe.cnpjTransp,
              cnpjTranspFormatado: this.formatarCpfCnpj(mdfe.cnpjTransp),
              status: mdfe.status,
              numeroMdfe: mdfe.numeroMdfe,
              caminhoMdfe: mdfe.caminhoMdfe,
              munCarrCep: mdfe.munCarrCep,
              munCarrCodigoIbge: mdfe.munCarrCodigoIbge,
              munCarrNome: mdfe.munCarrNome,
              munCarrUf: mdfe.munCarrUf,
              munIniCep: mdfe.munIniCep,
              munIniUf: mdfe.munIniUf,
              munFimCep: mdfe.munFimCep,
              munFimUf: mdfe.munFimUf,
              observacao: mdfe.observacao,
              prodPredCean: mdfe.prodPredCean,
              prodPredNcm: mdfe.prodPredNcm,
              prodPredNome: mdfe.prodPredNome,
              prodPredTipoCarga: mdfe.prodPredTipoCarga,
              idVeiculo: mdfe.veiculo ? mdfe.veiculo.id : 0,
              idMotorista: mdfe.motorista ? mdfe.motorista.id : 0,
              acoes: [
                {
                  icon: "mdi-pencil",
                  callback: () => this.editarMdfe(mdfe),
                },
                {
                  icon: "mdi-delete",
                  callback: () => this.validarMdfe(mdfe),
                },
                {
                  icon: "mdi-cancel",
                  callback: () => this.cancelarMdfe(mdfe),
                },
                {
                  icon: "mdi-download",
                  callback: () => this.downloadMdfe(mdfe),
                },
              ],
            };
          });

          this.showLoaderListaMdfe = false;
        })
        .catch((error) => {
          this.listaMdfes = [];
          this.showLoaderListaMdfe = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Mdfes: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Mdfes: " + error.message
            );
          }
        });
    },

    carregarMuncDesc(idMdfeAvulso, maxRetries = 5, retryDelay = 1000) {
      this.selectedMunDesc = [];
      this.showLoaderListaMunDesc = true;
      this.listaMunDesc = [];

      const tryFetchMunDesc = (retries) => {
        EmissaoViagem.getMunDesc(idMdfeAvulso)
          .then((response) => {
            this.listaMunDesc = response.data.map((muncDesc) => {
              return {
                id: muncDesc.id,
                idEmissaoViagemMdfe: muncDesc.mdfeAvulso.id,
                munNome: muncDesc.munNome,
                cep: muncDesc.cep,
                cepFormatado: muncDesc.cep,
                uf: muncDesc.uf,
                codigoIbge: muncDesc.codigoIbge,
                acoes: [
                  {
                    icon: "mdi-pencil",
                    callback: () => this.editarMunDesc(muncDesc),
                  },
                ],
              };
            });

            this.showLoaderListaMunDesc = false;
          })
          .catch((error) => {
            if (retries > 0) {
              console.log(
                `Tentativa falhou. Restam ${retries} tentativas. Tentando novamente em ${
                  retryDelay / 1000
                } segundos...`
              );
              setTimeout(() => tryFetchMunDesc(retries - 1), retryDelay);
            } else {
              this.listaMunDesc = [];
              this.showLoaderListaMunDesc = false;

              if (error.response) {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os Municipios de Descarregamento: " +
                    error.response.data.mensagem
                );
              } else {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os Municipios de Descarregamento: " +
                    error.message
                );
              }
            }
          });
      };

      // Inicia a primeira tentativa
      tryFetchMunDesc(maxRetries);
    },

    carregarPercurso(idMdfeAvulso, maxRetries = 5, retryDelay = 1000) {
      this.selectedPercurso = [];
      this.showLoaderListaPercurso = true;
      this.listaPercurso = [];

      const tryFetchPercurso = (retries) => {
        EmissaoViagem.getPercurso(idMdfeAvulso)
          .then((response) => {
            this.listaPercurso = response.data.map((percurso) => {
              return {
                id: percurso.id,
                idEmissaoViagemMdfe: percurso.mdfeAvulso.id,
                munNome: percurso.munNome,
                uf: percurso.uf,
                acoes: [
                  {
                    icon: "mdi-pencil",
                    callback: () => this.deletarPercurso(percurso),
                  },
                ],
              };
            });

            this.showLoaderListaPercurso = false;
          })
          .catch((error) => {
            if (retries > 0) {
              console.log(
                `Tentativa falhou. Restam ${retries} tentativas. Tentando novamente em ${
                  retryDelay / 1000
                } segundos...`
              );
              setTimeout(() => tryFetchPercurso(retries - 1), retryDelay);
            } else {
              this.listaPercurso = [];
              this.showLoaderListaPercurso = false;

              if (error.response) {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os Percursos: " +
                    error.response.data.mensagem
                );
              } else {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os Percursos: " + error.message
                );
              }
            }
          });
      };

      tryFetchPercurso(maxRetries);
    },

    downloadCte(emissaoViagemCte, path, chave, extension) {
      this.itemCte = emissaoViagemCte;

      if (this.itemCte.status != "AUTORIZADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! CTE não validado."
        );
        return;
      }

      if (!chave) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Numero da chave não encontrado."
        );
        return;
      }

      if (!path) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Caminho do arquivo CTE não encontrado. Favor entrar em contato com o suporte!"
        );
        return;
      }

      let type = "";
      extension == "pdf" ? (type = "application/pdf") : "text/xml";

      Movimentacao.download(path, "CTE").then((response) => {
        var blob = new Blob([this.base64ToArrayBuffer(response.data)], {
          type: type,
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = chave + "." + extension;
        link.download = fileName;
        link.click();
      });
    },

    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },

    cpfCnpjMask(value) {
      value = value.toString().replace(/\D/g, "");

      if (value && value.length === 11) {
        return "###.###.###-###";
      } else if (value && value.length === 14) {
        return "##.###.###/####-##";
      }

      return "##.###.###/####-##";
    },

    foneMask(value) {
      value = value.toString().replace(/\D/g, "");

      if (value && value.length === 10) {
        return "(##)####-####";
      }
      return "(##)#####-####";
    },

    formatarCpfCnpj(doc) {
      if (!doc) return "";

      // Remove todos os caracteres não numéricos
      doc = doc.toString().replace(/\D/g, "");

      // Garante um CNPJ com no máximo 14 dígitos
      doc = doc.substr(0, 14);

      // Formatação de CNPJ: XX.XXX.XXX/XXXX-XX
      let docFormatado = `${doc.substr(0, 2)}.${doc.substr(2, 3)}.${doc.substr(
        5,
        3
      )}/${doc.substr(8, 4)}-${doc.substr(12, 2)}`;

      // Se for um CPF (11 dígitos), aplica a máscara de CPF: 000.000.000-00
      if (doc.length === 11) {
        docFormatado = doc.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
          "$1.$2.$3-$4"
        );
      }

      return docFormatado;
    },

    consultarCnpjRemetente() {
      if (this.somenteLeituraCnpjRemetente) {
        return;
      }

      if (!this.validarCNPJ(this.itemNotaFiscal.cpfCnpjRemetenteFormatado)) {
        this.$refs.message.showMsg(
          "Atenção!",
          "CNPJ inválido! Favor tente novamente."
        );

        this.limparDadosRemetente();

        setTimeout(() => {
          this.$refs.cpfCnpjRemetenteInput.focus();
          this.$refs.cpfCnpjRemetenteInput.focus();
        }, 0);

        this.camposDesabilitadoRemetente = false;
        this.somenteLeituraCnpjRemetente = false;

        return;
      }

      const cnpj = this.itemNotaFiscal.cpfCnpjRemetenteFormatado.replace(
        /\D/g,
        ""
      );

      EmissaoViagem.getCpfCnpj(cnpj)
        .then((response) => {
          const data = response.data;

          this.itemNotaFiscal.idRemetente = data.id || "";
          this.itemNotaFiscal.razaoSocialRemetente = data.razaoSocial || "";
          this.itemNotaFiscal.nomeFantasiaRemetente = data.nomeFantasia || "";
          this.itemNotaFiscal.inscricaoEstadualRemetente =
            data.inscricaoEstadual || "";
          this.itemNotaFiscal.logradouroRemetente = data.logradouro || "";
          this.itemNotaFiscal.numeroRemetente = data.numero || "";
          this.itemNotaFiscal.complementoRemetente = data.complemento || "";
          this.itemNotaFiscal.bairroRemetente = data.bairro || "";
          this.itemNotaFiscal.codigoIbgeRemetente = data.codigoIbge || "";
          this.itemNotaFiscal.municipioRemetente = data.municipio || "";
          this.itemNotaFiscal.cepRemetenteFormatado = data.cep || "";
          this.itemNotaFiscal.ufRemetente = data.uf || "";
          this.itemNotaFiscal.foneRemetenteFormatado = data.fone || "";
          this.itemNotaFiscal.emailRemetente = data.email || "";
          this.itemNotaFiscal.observacaoRemetente = data.observacao || "";

          this.loadMunicipiosRemetente();

          this.camposDesabilitadoRemetente = false;
          this.somenteLeituraCnpjRemetente = true;

          setTimeout(() => {
            this.$refs.razaoSocialRemetenteInput.focus();
          }, 0);
        })
        .catch(() => {
          this.limparDadosRemetente();

          this.camposDesabilitadoRemetente = false;
          this.somenteLeituraCnpjRemetente = true;

          setTimeout(() => {
            this.$refs.razaoSocialRemetenteInput.focus();
          }, 0);
        });
    },

    consultarcpfCnpjDestinatario() {
      if (this.somenteLeituracpfCnpjDestinatario) {
        return;
      }

      if (
        !this.validarCNPJ(this.itemNotaFiscal.cpfCnpjDestinatarioFormatado) &&
        this.itemNotaFiscal.length == 18
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "CNPJ inválido! Favor tente novamente."
        );

        this.limparDadosDestinatario();

        setTimeout(() => {
          this.$refs.cpfCnpjDestinatarioInput.focus();
        }, 0);

        this.camposDesabilitadoDestinatario = false;
        this.somenteLeituracpfCnpjDestinatario = false;

        return;
      }

      if (
        !this.validarCPF(this.itemNotaFiscal.cpfCnpjDestinatarioFormatado) &&
        this.itemNotaFiscal.length == 14
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "CPF inválido! Favor tente novamente."
        );

        this.limparDadosDestinatario();

        setTimeout(() => {
          this.$refs.cpfCnpjDestinatarioInput.focus();
        }, 0);

        this.camposDesabilitadoDestinatario = false;
        this.somenteLeituracpfCnpjDestinatario = false;

        return;
      }

      const cpfCnpj = this.itemNotaFiscal.cpfCnpjDestinatarioFormatado.replace(
        /\D/g,
        ""
      );

      EmissaoViagem.getCpfCnpj(cpfCnpj)
        .then((response) => {
          const data = response.data;

          this.itemNotaFiscal.idDestinatario = data.id || "";
          this.itemNotaFiscal.razaoSocialDestinatario = data.razaoSocial || "";
          this.itemNotaFiscal.nomeFantasiaDestinatario =
            data.nomeFantasia || "";
          this.itemNotaFiscal.inscricaoEstadualDestinatario =
            data.inscricaoEstadual || "";
          this.itemNotaFiscal.logradouroDestinatario = data.logradouro || "";
          this.itemNotaFiscal.numeroDestinatario = data.numero || "";
          this.itemNotaFiscal.complementoDestinatario = data.complemento || "";
          this.itemNotaFiscal.bairroDestinatario = data.bairro || "";
          this.itemNotaFiscal.codigoIbgeDestinatario = data.codigoIbge || "";
          this.itemNotaFiscal.municipioDestinatario = data.municipio || "";
          this.itemNotaFiscal.cepDestinatarioFormatado = data.cep;
          this.itemNotaFiscal.ufDestinatario = data.uf || "";
          this.itemNotaFiscal.foneDestinatarioFormatado = data.fone || "";
          this.itemNotaFiscal.emailDestinatario = data.email || "";
          this.itemNotaFiscal.observacaoDestinatario = data.observacao || "";

          this.loadMunicipiosDestinatario();

          this.camposDesabilitadoDestinatario = false;
          this.somenteLeituracpfCnpjDestinatario = true;

          setTimeout(() => {
            this.$refs.razaoSocialDestinatarioInput.focus();
          }, 0);
        })
        .catch(() => {
          this.limparDadosDestinatario();

          if (this.itemNotaFiscal.cpfCnpjDestinatarioFormatado.length >= 14) {
            this.camposDesabilitadoDestinatario = false;
            this.somenteLeituracpfCnpjDestinatario = true;
          } else {
            this.somenteLeituracpfCnpjDestinatario = false;
          }

          setTimeout(() => {
            this.$refs.razaoSocialDestinatarioInput.focus();
          }, 0);
        });
    },

    limparDadosRemetente() {
      this.itemNotaFiscal.idRemetente = 0;
      this.itemNotaFiscal.razaoSocialRemetente = "";
      this.itemNotaFiscal.nomeFantasiaRemetente = "";
      this.itemNotaFiscal.inscricaoEstadualRemetente = "";
      this.itemNotaFiscal.logradouroRemetente = "";
      this.itemNotaFiscal.numeroRemetente = "";
      this.itemNotaFiscal.complementoRemetente = "";
      this.itemNotaFiscal.bairroRemetente = "";
      this.itemNotaFiscal.codigoIbgeRemetente = "";
      this.itemNotaFiscal.municipioRemetente = "";
      this.itemNotaFiscal.cepRemetenteFormatado = "";
      this.itemNotaFiscal.ufRemetente = "";
      this.itemNotaFiscal.foneRemetenteFormatado = "";
      this.itemNotaFiscal.emailRemetente = "";
      this.itemNotaFiscal.observacaoRemetente = "";
    },

    limparDadosDestinatario() {
      this.itemNotaFiscal.idDestinatario = 0;
      this.itemNotaFiscal.razaoSocialDestinatario = "";
      this.itemNotaFiscal.nomeFantasiaDestinatario = "";
      this.itemNotaFiscal.inscricaoEstadualDestinatario = "";
      this.itemNotaFiscal.logradouroDestinatario = "";
      this.itemNotaFiscal.numeroDestinatario = "";
      this.itemNotaFiscal.complementoDestinatario = "";
      this.itemNotaFiscal.bairroDestinatario = "";
      this.itemNotaFiscal.codigoIbgeDestinatario = "";
      this.itemNotaFiscal.municipioDestinatario = "";
      this.itemNotaFiscal.cepDestinatarioFormatado = "";
      this.itemNotaFiscal.ufDestinatario = "";
      this.itemNotaFiscal.foneDestinatarioFormatado = "";
      this.itemNotaFiscal.emailDestinatario = "";
      this.itemNotaFiscal.observacaoDestinatario = "";
    },

    limparDadosNotaFiscal() {
      this.itemNotaFiscal.id = 0;
      this.itemNotaFiscal.idEmissaoViagem = this.item.id;
      this.itemNotaFiscal.chave = "";
      this.itemNotaFiscal.emitente = "";
      this.itemNotaFiscal.destinatario = "";
      this.itemNotaFiscal.qtdeCarga = 0;
      this.itemNotaFiscal.pesoCarga = 0;
      this.itemNotaFiscal.valorCarga = 0;
      this.itemNotaFiscal.qtdeCargaFormatado = 0;
      this.itemNotaFiscal.pesoCargaFormatado = 0;
      this.itemNotaFiscal.valorCargaFormatado = 0;
      this.itemNotaFiscal.cpfCnpjRemetenteFormatado = "";
      this.itemNotaFiscal.cpfCnpjDestinatarioFormatado = "";
    },

    limparDadosCompletoRemetente() {
      this.itemNotaFiscal.cpfCnpjRemetente = "";
      this.itemNotaFiscal.cpfCnpjRemetenteFormatado = "";

      this.limparDadosRemetente();
      this.somenteLeituraCnpjRemetente = false;

      setTimeout(() => {
        this.$refs.cpfCnpjRemetenteInput.focus();
      }, 0);
    },

    limparDadosCompletoDestinatario() {
      this.itemNotaFiscal.cpfCnpjDestinatario = "";
      this.itemNotaFiscal.cpfCnpjDestinatarioFormatado = "";

      this.limparDadosDestinatario();
      this.somenteLeituracpfCnpjDestinatario = false;

      setTimeout(() => {
        this.$refs.cpfCnpjDestinatarioInput.focus();
      }, 0);
    },

    novaEmissaoViagem() {
      this.item = {
        id: 0,
        descricao: "",
        idParticipante: 0,
        razaoSocial: "",
        nomeFantasia: "",
        cpfCnpj: "",
        idUsuario: localStorage.getItem("id"),
        dataHora: "",
        qtdeMdfe: 0,
        qtdeCte: 0,
        prepararCte: false,
        prepararMdfe: false,
        validado: false,
        mdfe: false,
      };

      this.dialogCadastro = true;
    },

    novaNotaFiscal() {
      this.itemNotaFiscal = {
        id: 0,
        idEmissaoViagem: this.item.id,
        chave: "",
        emitente: "",
        destinatario: "",
        qtdeCarga: 0,
        pesoCarga: 0,
        valorCarga: 0,
        qtdeCargaFormatado: 0,
        pesoCargaFormatado: 0,
        valorCargaFormatado: 0,
        valorCargaFormatadoGrid: "",
        idRemetente: 0,
        razaoSocialRemetente: "",
        nomeFantasiaRemetente: "",
        cpfCnpjRemetente: "",
        cpfCnpjRemetenteFormatado: "",
        inscricaoEstadualRemetente: "",
        emailRemetente: "",
        logradouroRemetente: "",
        numeroRemetente: "",
        complementoRemetente: "",
        bairroRemetente: "",
        codigoIbgeRemetente: "",
        municipioRemetente: "",
        cepRemetente: "",
        cepRemetenteFormatado: "",
        ufRemetente: "",
        foneRemetenteFormatado: "",
        foneRemetente: "",
        observacaoRemetente: "",
        idDestinatario: 0,
        razaoSocialDestinatario: "",
        nomeFantasiaDestinatario: "",
        cpfCnpjDestinatario: "",
        cpfCnpjDestinatarioFormatado: "",
        inscricaoEstadualDestinatario: "",
        emailDestinatario: "",
        logradouroDestinatario: "",
        numeroDestinatario: "",
        complementoDestinatario: "",
        bairroDestinatario: "",
        codigoIbgeDestinatario: "",
        municipioDestinatario: "",
        cepDestinatario: "",
        cepDestinatarioFormatado: "",
        ufDestinatario: "",
        foneDestinatario: "",
        foneDestinatarioFormatado: "",
        observacaoDestinatario: "",
        importada: false,
      };

      this.loadEstados();

      this.tabNotaFiscal = "dadosNota";
      this.somenteLeituraCnpjRemetente = false;
      this.somenteLeituracpfCnpjDestinatario = false;
      this.processoEmAndamento = false;
      this.dialogNotaFiscal = true;

      setTimeout(() => {
        this.$refs.chaveInput.focus();
      }, 0);
    },

    clickBtnNovoPercurso() {
      this.itemPercurso = {
        id: 0,
        idMdfeAvulso: this.itemMdfe.id,
        uf: "",
      };

      this.dialogPercurso = true;
    },

    novoMotorista() {
      this.itemMotorista = {
        id: 0,
        idParticipante: this.item.id,
        idMotorista: 0,
        nome: "",
        cpf: "",
        cpfFormatado: "",
        ativo: true,
      };

      this.loadEstados();
      this.somenteLeituraCpf = false;
      this.dialogMotorista = true;
    },

    novoVeiculo() {
      this.itemVeiculo = {
        id: 0,
        idParticipante: this.item.id,
        idVeiculo: 0,
        placa: "",
        renavam: "",
        tipoCarroceria: 0,
        tara: "",
        capKg: "",
        ufLicenciamento: "",
        rntrc: "",
        tipoRodado: 0,
        ativo: true,
      };

      this.somenteLeituraPlaca = false;
      this.dialogVeiculo = true;
    },

    importarNotaFiscal() {
      this.xmlNfeFile = null;
      this.xmlNfeConteudo = "";
      this.dialogImportarNotaFiscal = true;
    },

    criarItemEmissaoViagem(emissaoViagem) {
      return {
        id: emissaoViagem.id,
        descricao: emissaoViagem.descricao,
        idParticipante: emissaoViagem.idParticipante,
        razaoSocial: emissaoViagem.razaoSocial,
        nomeFantasia: emissaoViagem.nomeFantasia,
        cpfCnpj: emissaoViagem.cpfCnpj,
        idUsuario: emissaoViagem.idUsuario,
        dataHora: emissaoViagem.dataHora,
        qtdeMdfe: emissaoViagem.qtdeMdfe,
        qtdeCte: emissaoViagem.qtdeCte,
        prepararCte: emissaoViagem.prepararCte,
        prepararMdfe: emissaoViagem.prepararMdfe,
        validado: emissaoViagem.validado,
        mdfe: emissaoViagem.mdfe,
      };
    },

    criarItemNotaFiscal(emissaoViagemNotaFiscal) {
      return {
        id: emissaoViagemNotaFiscal.id,
        idEmissaoViagem: emissaoViagemNotaFiscal.idEmissaoViagem,
        chave: emissaoViagemNotaFiscal.chave,
        qtdeCargaFormatado: emissaoViagemNotaFiscal.qtdeCargaFormatado,
        pesoCargaFormatado: emissaoViagemNotaFiscal.pesoCargaFormatado,
        valorCarga: emissaoViagemNotaFiscal.valorCarga,
        valorCargaFormatado: emissaoViagemNotaFiscal.valorCargaFormatado,
        idRemetente: emissaoViagemNotaFiscal.idRemetente,
        razaoSocialRemetente: emissaoViagemNotaFiscal.razaoSocialRemetente,
        nomeFantasiaRemetente: emissaoViagemNotaFiscal.nomeFantasiaRemetente,
        cpfCnpjRemetenteFormatado:
          emissaoViagemNotaFiscal.cpfCnpjRemetenteFormatado,
        inscricaoEstadualRemetente:
          emissaoViagemNotaFiscal.inscricaoEstadualRemetente,
        emailRemetente: emissaoViagemNotaFiscal.emailRemetente,
        logradouroRemetente: emissaoViagemNotaFiscal.logradouroRemetente,
        numeroRemetente: emissaoViagemNotaFiscal.numeroRemetente,
        complementoRemetente: emissaoViagemNotaFiscal.complementoRemetente,
        bairroRemetente: emissaoViagemNotaFiscal.bairroRemetente,
        codigoIbgeRemetente: emissaoViagemNotaFiscal.codigoIbgeRemetente,
        municipioRemetente: emissaoViagemNotaFiscal.municipioRemetente,
        cepRemetenteFormatado: emissaoViagemNotaFiscal.cepRemetenteFormatado,
        ufRemetente: emissaoViagemNotaFiscal.ufRemetente,
        foneRemetenteFormatado: emissaoViagemNotaFiscal.foneRemetenteFormatado,
        observacaoRemetente: emissaoViagemNotaFiscal.observacaoRemetente,
        idDestinatario: emissaoViagemNotaFiscal.idDestinatario,
        razaoSocialDestinatario:
          emissaoViagemNotaFiscal.razaoSocialDestinatario,
        nomeFantasiaDestinatario:
          emissaoViagemNotaFiscal.nomeFantasiaDestinatario,
        cpfCnpjDestinatarioFormatado:
          emissaoViagemNotaFiscal.cpfCnpjDestinatarioFormatado,
        inscricaoEstadualDestinatario:
          emissaoViagemNotaFiscal.inscricaoEstadualDestinatario,
        emailDestinatario: emissaoViagemNotaFiscal.emailDestinatario,
        logradouroDestinatario: emissaoViagemNotaFiscal.logradouroDestinatario,
        numeroDestinatario: emissaoViagemNotaFiscal.numeroDestinatario,
        complementoDestinatario:
          emissaoViagemNotaFiscal.complementoDestinatario,
        bairroDestinatario: emissaoViagemNotaFiscal.bairroDestinatario,
        codigoIbgeDestinatario: emissaoViagemNotaFiscal.codigoIbgeDestinatario,
        municipioDestinatario: emissaoViagemNotaFiscal.municipioDestinatario,
        cepDestinatarioFormatado:
          emissaoViagemNotaFiscal.cepDestinatarioFormatado,
        ufDestinatario: emissaoViagemNotaFiscal.ufDestinatario,
        foneDestinatarioFormatado:
          emissaoViagemNotaFiscal.foneDestinatarioFormatado,
      };
    },

    criarCte(emissaoViagemCte) {
      return {
        id: emissaoViagemCte.id,
        idEmissaoViagem: emissaoViagemCte.emissaoViagem.id,
        // chave: emissaoViagemNotaFiscal.chave,
        // qtdeCargaFormatado: emissaoViagemNotaFiscal.qtdeCargaFormatado,
        // pesoCargaFormatado: emissaoViagemNotaFiscal.pesoCargaFormatado,
        // valorCargaFormatado: emissaoViagemNotaFiscal.valorCargaFormatado,
        idRemetente: emissaoViagemCte.remetente.id,
        razaoSocialRemetente: emissaoViagemCte.remetente.razaoSocial,
        cpfCnpjRemetenteFormatado: emissaoViagemCte.remetente.cpfCnpj,

        // idDestinatario: emissaoViagemNotaFiscal.idDestinatario,
        // razaoSocialDestinatario: emissaoViagemNotaFiscal.razaoSocialDestinatario,
        // nomeFantasiaDestinatario: emissaoViagemNotaFiscal.nomeFantasiaDestinatario,
        // cpfCnpjDestinatarioFormatado:
        //   emissaoViagemNotaFiscal.cpfCnpjDestinatarioFormatado,
        // inscricaoEstadualDestinatario:
        //   emissaoViagemNotaFiscal.inscricaoEstadualDestinatario,
        // emailDestinatario: emissaoViagemNotaFiscal.emailDestinatario,
        // logradouroDestinatario: emissaoViagemNotaFiscal.logradouroDestinatario,
        // numeroDestinatario: emissaoViagemNotaFiscal.numeroDestinatario,
        // complementoDestinatario: emissaoViagemNotaFiscal.complementoDestinatario,
        // bairroDestinatario: emissaoViagemNotaFiscal.bairroDestinatario,
        // codigoIbgeDestinatario: emissaoViagemNotaFiscal.codigoIbgeDestinatario,
        // municipioDestinatario: emissaoViagemNotaFiscal.municipioDestinatario,
        // cepDestinatarioFormatado: emissaoViagemNotaFiscal.cepDestinatarioFormatado,
        // ufDestinatario: emissaoViagemNotaFiscal.ufDestinatario,
        // foneDestinatarioFormatado: emissaoViagemNotaFiscal.foneDestinatarioFormatado,
      };
    },

    editarEmissaoViagem(emissaoViagem) {
      this.item = this.criarItemEmissaoViagem(emissaoViagem);
      this.dialogCadastro = true;
    },

    editarNotaFiscal(emissaoViagemNotaFiscal) {
      // this.itemNotaFiscal = this.criarItemNotaFiscal(emissaoViagemNotaFiscal);
      this.itemNotaFiscal = emissaoViagemNotaFiscal;

      this.loadEstados();
      this.loadMunicipiosDestinatario();
      this.loadMunicipiosRemetente();

      this.tabNotaFiscal = "dadosNota";
      this.somenteLeituraCnpjRemetente = true;
      this.somenteLeituracpfCnpjDestinatario = true;
      this.processoEmAndamento = false;
      this.dialogNotaFiscal = true;

      setTimeout(() => {
        this.$refs.chaveInput.focus();
      }, 0);
    },

    editarCte(emissaoViagemCte) {
      this.itemCte = emissaoViagemCte;

      if (this.item.prepararMdfe == true) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Cte ja em preparação."
        );
        return;
      }

      if (this.itemCte.status == "CANCELADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Cte cancelado."
        );
        return;
      }

      if (this.itemCte.status == "AUTORIZADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "CTe validado! Não pode ser mais editado!"
        );
        return;
      }

      this.carregarNotaFiscaisCte(
        this.itemCte.idEmissaoViagem,
        this.itemCte.idRemetente,
        this.itemCte.idDestinatario
      );

      this.tabCte = "dadosCTE";
      this.dialogCte = true;
    },

    async editarMdfe(emissaoViagemMdfe) {
      this.itemMdfe = emissaoViagemMdfe;

      if (this.itemMdfe.status == "AUTORIZADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! MDFE já validado."
        );
        return;
      }

      if (this.itemMdfe.status == "CANCELADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! MDFE cancelado."
        );
        return;
      }

      await this.loadEstados();

      await this.loadMunicipiosCarregamento();

      await this.loadMotorista();

      await this.loadVeiculo();

      await this.carregarMuncDesc(emissaoViagemMdfe.id);

      await this.carregarPercurso(emissaoViagemMdfe.id);

      this.tabMdfe = "dadosMDFE";
      this.dialogMdfe = true;
    },

    editarMunDesc(emissaoViagemMdfeMunDesc) {
      this.itemMunDesc = emissaoViagemMdfeMunDesc;
      this.dialogMunDesc = true;
    },

    deletarPercurso(emissaoViagemMdfePercurso) {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja excluir esta nota Fiscal?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Excluindo Nota Fiscal...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (emissaoViagemMdfePercurso.id > 0) {
              EmissaoViagem.deletePercurso(emissaoViagemMdfePercurso.id)
                .then((response) => {
                  console.log("Percurso excluída com sucesso!", response);
                  this.snackSuccess("Percurso excluída com sucesso!");
                  this.carregarPercurso(this.itemMdfe.id);
                })
                .catch((error) => {
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir Percurso: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir Percurso: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg(
                "ATENÇÃO!",
                "Percurso não encontrada!"
              );
            }
          }
          this.setLoading(false);
        }
      );
    },

    deletarNotaFiscal(emissaoViagemNotaFiscal) {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja excluir esta nota Fiscal?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Excluindo Nota Fiscal...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (emissaoViagemNotaFiscal.id > 0) {
              EmissaoViagem.deleteNotaFiscal(emissaoViagemNotaFiscal.id)
                .then((response) => {
                  console.log("Nota Fiscal excluída com sucesso!", response);
                  this.snackSuccess("Nota Fiscal excluída com sucesso!");
                  this.carregarNotaFiscais(
                    emissaoViagemNotaFiscal.idEmissaoViagem
                  );
                })
                .catch((error) => {
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir nota fiscal: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir nota fiscal: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg(
                "ATENÇÃO!",
                "Nota Fiscal não encontrada!"
              );
            }
          }
          this.setLoading(false);
        }
      );
    },

    listarNotaFiscal(emissaoViagem) {
      this.item = this.criarItemEmissaoViagem(emissaoViagem);

      if (emissaoViagem.prepararCte == true) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Cte já em preparação."
        );
        return;
      }

      this.carregarNotaFiscais(emissaoViagem.id);

      this.item = this.criarItemEmissaoViagem(emissaoViagem);
      this.dialogListaNotaFiscal = true;
    },

    listarCte(emissaoViagem) {
      this.item = this.criarItemEmissaoViagem(emissaoViagem);

      if (emissaoViagem.prepararCte == false) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Nenhum cte em preparação foi encontrado!"
        );
        return;
      }

      this.carregarCtes(emissaoViagem.id);
      this.dialogListaCte = true;
    },

    listarMotoristas() {
      this.carregarMotoristas(this.item.idParticipante);
      this.dialogListaMotorista = true;
    },

    carregarMotoristas(idTransportador) {
      this.selectedMotorista = [];
      this.showLoaderListaMotorista = true;
      this.listaMotoristas = [];

      Transportador.getMotoristas(idTransportador)
        .then((response) => {
          this.listaMotoristas = response.data.map((participanteMotorista) => {
            return {
              id: participanteMotorista.id,
              idParticipante: participanteMotorista.participante.id,
              idMotorista: participanteMotorista.motorista.id,
              nome: participanteMotorista.motorista.nome,
              cpf: participanteMotorista.motorista.cpf,
              ativo: participanteMotorista.motorista.ativo,
              cpfFormatado: this.formatarCpfCnpj(
                participanteMotorista.motorista.cpf
              ),
              acoes: [
                {
                  icon: "mdi-pencil",
                  callback: () => this.editarMotorista(participanteMotorista),
                },
                {
                  icon: "mdi-delete",
                  callback: () => this.deletarMotorista(participanteMotorista),
                },
              ],
            };
          });

          this.showLoaderListaMotorista = false;
        })
        .catch((error) => {
          this.listaMotoristas = [];
          this.showLoaderListaMotorista = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Motoristas: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Motoristas: " + error.message
            );
          }
        });
    },

    listarVeiculos() {
      this.carregarVeiculos(this.item.idParticipante);
      this.dialogListaVeiculo = true;
    },

    carregarVeiculos(idTransportador) {
      this.selectedVeiculo = [];
      this.showLoaderListaVeiculo = true;
      this.listaVeiculos = [];

      Transportador.getVeiculos(idTransportador)
        .then((response) => {
          this.listaVeiculos = response.data.map((participanteVeiculo) => {
            return {
              id: participanteVeiculo.id,
              idParticipante: participanteVeiculo.participante.id,
              idVeiculo: participanteVeiculo.veiculo.id,
              placa: participanteVeiculo.veiculo.placa,
              tipoVeiculo: participanteVeiculo.veiculo.tipoVeiculo,
              renavam: participanteVeiculo.veiculo.renavam,
              tara: participanteVeiculo.veiculo.tara,
              capKg: participanteVeiculo.veiculo.capKg,
              ufLicenciamento: participanteVeiculo.veiculo.ufLicenciamento,
              rntrc: participanteVeiculo.veiculo.rntrc,
              tipoRodado: participanteVeiculo.veiculo.tipoRodado,
              tipoCarroceria: participanteVeiculo.veiculo.tipoCarroceria,
              ativo: participanteVeiculo.veiculo.ativo,
              acoes: [
                {
                  icon: "mdi-pencil",
                  callback: () => this.editarVeiculo(participanteVeiculo),
                },
                {
                  icon: "mdi-delete",
                  callback: () => this.deletarVeiculo(participanteVeiculo),
                },
              ],
            };
          });

          this.showLoaderListaVeiculo = false;
        })
        .catch((error) => {
          this.listaVeiculos = [];
          this.showLoaderListaVeiculo = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Veiculos: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os Veiculos: " + error.message
            );
          }
        });
    },

    clickBtnCancelarMotorista() {
      this.dialogMotorista = false;
    },

    clickBtnCancelarVeiculo() {
      this.dialogVeiculo = false;
    },

    clickBtnCancelarListaMotorista() {
      this.dialogListaMotorista = false;
    },

    clickBtnCancelarListaVeiculo() {
      this.dialogListaVeiculo = false;
    },

    clickBtnSalvarMotorista() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          this.processoEmAndamento = true;

          if (resposta) {
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioMotorista()) {
              if (this.itemMotorista.id === 0) {
                Transportador.insertMotorista(this.itemMotorista)
                  .then((response) => {
                    console.log("Motorista criada com sucesso!", response);
                    this.snackSuccess("Motorista criada com sucesso!");

                    this.processoEmAndamento = false;
                    this.dialogMotorista = false;
                    this.carregarMotoristas(this.itemMotorista.idParticipante);
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o Motorista: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o Motorista: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              } else {
                Transportador.updateMotorista(
                  this.itemMotorista.id,
                  this.itemMotorista
                )
                  .then((response) => {
                    console.log("Motorista atualizada com sucesso!", response);
                    this.snackSuccess("Motorista atualizada com sucesso!");

                    this.processoEmAndamento = false;
                    this.dialogMotorista = false;
                    this.carregarMotoristas(this.itemMotorista.idParticipante);
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o Motorista: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o Motorista: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              }
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    validarDadosFormularioMotorista() {
      this.itemMotorista.nome = this.itemMotorista.nome.trim();
      this.itemMotorista.cpf = this.itemMotorista.cpfFormatado.replace(
        /\D/g,
        ""
      );

      if (!this.itemMotorista.cpf) {
        this.$refs.message.showMsg("Atenção!", "Campo CPF é obrigatório");
        return false;
      }

      if (!this.itemMotorista.nome) {
        this.$refs.nomeMotoristaInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo Nome é obrigatório");
        return false;
      }

      if (!this.validarCPF(this.itemMotorista.cpf)) {
        this.$refs.cpfCnpjInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "CPF inválido! Favor tente novamente."
        );
        return false;
      }

      return true;
    },

    editarMotorista(participanteMotorista) {
      this.itemMotorista = participanteMotorista;

      this.dialogMotorista = true;
    },

    deletarMotorista(participanteMotorista) {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja remover o Motorista do Transportador?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Removendo...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (participanteMotorista.id > 0) {
              Transportador.deletarMotorista(participanteMotorista.id)
                .then((response) => {
                  console.log("Remoção efetuada com sucesso!", response);
                  this.snackSuccess("Remoção efetuada com sucesso!!");
                  this.carregarMotoristas(participanteMotorista.idParticipante);
                })
                .catch((error) => {
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao remover o motorista: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir o motorista: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg(
                "ATENÇÃO!",
                "Motorista não encontrada!"
              );
            }
          }
          this.setLoading(false);
        }
      );
    },

    deletarViagem(emissaoViagem) {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja excluir esta Viagem?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Excluindo Viagem...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (emissaoViagem.id > 0) {
              EmissaoViagem.delete(emissaoViagem.id)
                .then((response) => {
                  console.log("Viagem excluída com sucesso!", response);
                  this.snackSuccess("Viagem excluída com sucesso!");
                  this.filtrar();
                })
                .catch((error) => {
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir Viagem: " + error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir Viagem: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg("ATENÇÃO!", "Viagem não encontrada!");
            }
          }
          this.setLoading(false);
        }
      );
    },

    clickBtnSalvarVeiculo() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          this.processoEmAndamento = true;

          if (resposta) {
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioVeiculo()) {
              if (this.itemVeiculo.id === 0) {
                Transportador.insertVeiculo(this.itemVeiculo)
                  .then((response) => {
                    console.log("Veiculo criada com sucesso!", response);
                    this.snackSuccess("Veiculo criada com sucesso!");

                    this.processoEmAndamento = false;
                    this.dialogVeiculo = false;
                    this.carregarVeiculos(this.itemVeiculo.idParticipante);
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o Veiculo: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar o Veiculo: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              } else {
                Transportador.updateVeiculo(
                  this.itemVeiculo.id,
                  this.itemVeiculo
                )
                  .then((response) => {
                    console.log("Veiculo atualizada com sucesso!", response);
                    this.snackSuccess("Veiculo atualizada com sucesso!");

                    this.processoEmAndamento = false;
                    this.dialogVeiculo = false;
                    this.carregarVeiculos(this.itemVeiculo.idParticipante);
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o Veiculo: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar o Veiculo: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              }
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    validarDadosFormularioVeiculo() {
      this.itemVeiculo.placa = this.itemVeiculo.placa.trim();

      if (!this.itemVeiculo.placa) {
        this.$refs.message.showMsg("Atenção!", "Campo Placa é obrigatório");
        return false;
      }

      if (!this.itemVeiculo.renavam) {
        this.$refs.message.showMsg("Atenção!", "Campo Renavam é obrigatório");
        return false;
      }

      if (
        this.itemVeiculo.renavam.includes(" ") ||
        this.itemVeiculo.renavam.length < 9 ||
        this.itemVeiculo.renavam.length > 11
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Valor inválido para o campo Renavam. Valor permitido entre 9 a 11 caracteres sem espaços."
        );
        return false;
      }

      if (!this.itemVeiculo.tara) {
        this.$refs.message.showMsg("Atenção!", "Campo tara é obrigatório");
        return false;
      }

      if (!this.itemVeiculo.capKg) {
        this.$refs.message.showMsg("Atenção!", "Campo capKg é obrigatório");
        return false;
      }

      if (this.itemVeiculo.tipoRodado <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Tipo Rodada é obrigatório"
        );
        return false;
      }

      if (this.itemVeiculo.tipoCarroceria <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Tipo Carroceria é obrigatório"
        );
        return false;
      }

      if (this.itemVeiculo.tipoVeiculo < 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Tipo Veiculo é obrigatório"
        );
        return false;
      }

      if (!this.itemVeiculo.rntrc) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Tipo Rodada é obrigatório"
        );
        return false;
      }

      if (!this.itemVeiculo.ufLicenciamento) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo UF Licenciamento é obrigatório"
        );
        return false;
      }

      return true;
    },

    editarVeiculo(participanteVeiculo) {
      this.itemVeiculo = participanteVeiculo;
      this.loadEstados();
      this.dialogVeiculo = true;
    },

    deletarVeiculo(participanteVeiculo) {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja remover o Veiculo do Transportador?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Removendo...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (participanteVeiculo.id > 0) {
              Transportador.deletarVeiculo(participanteVeiculo.id)
                .then((response) => {
                  console.log("Remoção efetuada com sucesso!", response);
                  this.snackSuccess("Remoção efetuada com sucesso!!");
                  this.carregarVeiculos(participanteVeiculo.idParticipante);
                })
                .catch((error) => {
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao remover o veiculo: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao excluir o veiculo: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg("ATENÇÃO!", "Veiculo não encontrada!");
            }
          }
          this.setLoading(false);
        }
      );
    },

    cancelarMdfe(emissaoViagemMdfe) {
      this.itemMdfe = emissaoViagemMdfe;

      if (!this.itemMdfe.numeroMdfe) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Chave não encontrada."
        );
        return;
      }

      if (this.itemMdfe.status == "CANCELADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! MDFE cancelado."
        );
        return;
      }

      if (this.itemMdfe.status != "AUTORIZADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! MDFE não validado."
        );
        return;
      }

      this.dialogCancelMdfe = true;
    },

    confirmarCancelamentoMdfe() {
      if (
        !this.justificativaCancelamentoMDfe ||
        this.justificativaCancelamentoMDfe.length < 15
      ) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "A justificativa é obrigatória e deve conter pelo menos 15 caracteres."
        );
        return;
      }

      this.dialogCancelMdfe = false;

      Mdfe.cancelar(
        this.itemMdfe.numeroMdfe,
        this.justificativaCancelamentoMDfe
      )
        .then(() => {
          this.$refs.message.showMsg(
            "SUCESSO!",
            "Cancelamento Concluído com sucesso!"
          );
          this.alterarStatusMdfe(this.itemMdfe.id, 'CANCELADO');
          this.carregarMdfes(this.itemMdfe.idEmissaoViagem);
        })
        .catch((err) => {
          this.$refs.message.showMsg(
            "ERRO!",
            "Não foi possível Cancelar o MDF-e, motivo: " +
              err.response.data.motivo
          );
          this.listaMdfes();
        });
    },

    alterarStatusMdfe(id, status) {
      EmissaoViagem.alterarStatusMdfe(id, status)
        .then(() => {
          console.log("Status do MDFe atualizado com sucesso!");
        })
        .catch((error) => {
          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao atualizar status mdfe: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao atualizar status mdfe: " + error
            );
          }
        });
    },

    cancelarCte(emissaoViagemCte) {
      this.itemCte = emissaoViagemCte;

      if (!this.itemCte.numeroCte) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Chave não encontrada."
        );
        return;
      }

      if (!this.itemCte.numeroProtocolo) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Protocolo não encontrado."
        );
        return;
      }

      if (this.itemCte.status == "CANCELADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Cte cancelado."
        );
        return;
      }

      if (this.itemCte.status != "AUTORIZADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! CT-e não validado."
        );
        return;
      }

      this.dialogCancelCte = true;
    },

    confirmarCancelamentoCte() {
      if (
        !this.justificativaCancelamentoCte ||
        this.justificativaCancelamentoCte.length < 15
      ) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "A justificativa é obrigatória e deve conter pelo menos 15 caracteres."
        );
        return;
      }

      this.dialogCancelCte = false;

      Cte.cancelarDocumento(
        this.itemCte.numeroCte,
        this.itemCte.numeroProtocolo,
        this.justificativaCancelamentoCte
      )
        .then(() => {
          this.$refs.message.showMsg(
            "SUCESSO!",
            "Cancelamento Concluído com sucesso!"
          );
          this.alterarStatusCte(this.itemCte.id, 'CANCELADO');
          this.carregarCtes(this.itemCte.idEmissaoViagem);
        })
        .catch((err) => {
          this.$refs.message.showMsg(
            "ERRO!",
            "Não foi possível Cancelar o CT-e, motivo: " +
              err.response.data.motivo
          );
          this.listaCtes();
        });
    },

    alterarStatusCte(id, status) {
      EmissaoViagem.alterarStatusCte(id, status)
        .then(() => {
          console.log("Status do CTe atualizado com sucesso!");
        })
        .catch((error) => {
          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao atualizar status CTe: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao atualizar status CTe: " + error
            );
          }
        });
    },

    downloadMdfe(emissaoViagemMdfe, path, chave, extension) {
      this.itemMdfe = emissaoViagemMdfe;

      if (this.itemMdfe.status != "AUTORIZADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! MDFE não validado."
        );
        return;
      }

      if (!chave) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Numero da chave não encontrado."
        );
        return;
      }

      if (!path) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Caminho do arquivo MDFE não encontrado. Favor entrar em contato com o suporte!"
        );
        return;
      }

      let type = "";
      extension == "pdf" ? (type = "application/pdf") : "text/xml";

      Movimentacao.download(path, "MDFE").then((response) => {
        var blob = new Blob([this.base64ToArrayBuffer(response.data)], {
          type: type,
        });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        var fileName = chave + "." + extension;
        link.download = fileName;
        link.click();
      });
    },

    listarMdfe(emissaoViagem) {
      this.item = this.criarItemEmissaoViagem(emissaoViagem);

      if (emissaoViagem.prepararCte == false) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Cte ainda em preparação."
        );
        return;
      }

      if (emissaoViagem.prepararMdfe == false) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Mdfe ainda nao entrou em preparação."
        );
        return;
      }

      this.carregarMdfes(emissaoViagem.id);
      this.dialogListaMdfe = true;
    },

    loadEstados(maxRetries = 5, retryDelay = 1000) {
      const tryFetchEstados = (retries) => {
        return Logradouro.getEstados()
          .then((response) => {
            this.estados = response.data;
          })
          .catch((error) => {
            if (retries > 0) {
              console.log(
                `Consulta Estado - Tentativa falhou. Restam ${retries} tentativas. Tentando novamente em ${
                  retryDelay / 1000
                } segundos...`
              );
              setTimeout(() => tryFetchEstados(retries - 1), retryDelay);
            } else {
              if (error.response) {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os estados: " +
                    error.response.data.mensagem
                );
              } else {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os estados: " + error
                );
              }
            }
          });
      };

      return tryFetchEstados(maxRetries);
    },

    loadMotorista(maxRetries = 5, retryDelay = 1000) {
      const tryFetchMotoristas = (retries) => {
        EmissaoViagem.getMotoristas(this.item.idParticipante)
          .then((response) => {
            this.motoristas = response.data;
          })
          .catch((error) => {
            if (retries > 0) {
              console.log(
                `Consulta Motorista - Tentativa falhou. Restam ${retries} tentativas. Tentando novamente em ${
                  retryDelay / 1000
                } segundos...`
              );
              setTimeout(() => tryFetchMotoristas(retries - 1), retryDelay);
            } else {
              if (error.response) {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os motoristas: " +
                    error.response.data.mensagem
                );
              } else {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os motoristas: " + error
                );
              }
            }
          });
      };

      tryFetchMotoristas(maxRetries);
    },

    loadVeiculo(maxRetries = 5, retryDelay = 1000) {
      const tryFetchVeiculos = (retries) => {
        EmissaoViagem.getVeiculos(this.item.idParticipante)
          .then((response) => {
            this.veiculos = response.data;
          })
          .catch((error) => {
            if (retries > 0) {
              console.log(
                `Consulta Veiculo - Tentativa falhou. Restam ${retries} tentativas. Tentando novamente em ${
                  retryDelay / 1000
                } segundos...`
              );
              setTimeout(() => tryFetchVeiculos(retries - 1), retryDelay);
            } else {
              if (error.response) {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os veiculos: " +
                    error.response.data.mensagem
                );
              } else {
                this.$refs.message.showMsg(
                  "ERROR!",
                  "Erro ao consultar os veiculos: " + error
                );
              }
            }
          });
      };

      tryFetchVeiculos(maxRetries);
    },

    loadParticipantes() {
      Transportador.getTransportadoresUsuario(localStorage.getItem("id"))
        .then((response) => {
          this.participantes = response.data;
        })
        .catch((error) => {
          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os participantes: " +
                error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao consultar os participantes: " + error.message
            );
          }
        });
    },

    loadMunicipiosRemetente() {
      if (this.itemNotaFiscal.ufRemetente) {
        Logradouro.getCidades(this.itemNotaFiscal.ufRemetente)
          .then((response) => {
            this.municipiosRemetente = response.data;
          })
          .catch((error) => {
            if (error.response) {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao consultar as cidades (remetente): " +
                  error.response.data.mensagem
              );
            } else {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao consultar as cidades (remetente): " + error.message
              );
            }
          });
      }
    },

    loadMunicipiosDestinatario() {
      if (this.itemNotaFiscal.ufDestinatario) {
        Logradouro.getCidades(this.itemNotaFiscal.ufDestinatario)
          .then((response) => {
            this.municipiosDestinatario = response.data;
          })
          .catch((error) => {
            if (error.response) {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao consultar as cidades (destinatario): " +
                  error.response.data.mensagem
              );
            } else {
              this.$refs.message.showMsg(
                "ERROR!",
                "Erro ao consultar as cidades (destinatario): " + error.message
              );
            }
          });
      }
    },

    loadMunicipiosCarregamento(maxRetries = 5, retryDelay = 1000) {
      if (this.itemMdfe.munCarrUf) {
        this.processoEmAndamento = true;

        const tryFetchCidades = (retries) => {
          Logradouro.getCidades(this.itemMdfe.munCarrUf)
            .then((response) => {
              this.municipiosCarregamento = response.data;
              this.processoEmAndamento = false;
            })
            .catch((error) => {
              if (retries > 0) {
                console.log(
                  `Consulta Municipio Carregamento - Tentativa falhou. Restam ${retries} tentativas. Tentando novamente em ${
                    retryDelay / 1000
                  } segundos...`
                );
                setTimeout(() => tryFetchCidades(retries - 1), retryDelay);
              } else {
                this.processoEmAndamento = false;
                if (error.response) {
                  this.$refs.message.showMsg(
                    "ERROR!",
                    "Erro ao consultar as cidades (mun. carregamento): " +
                      error.response.data.mensagem
                  );
                } else {
                  this.$refs.message.showMsg(
                    "ERROR!",
                    "Erro ao consultar as cidades (mun. carregamento): " + error
                  );
                }
              }
            });
        };

        tryFetchCidades(maxRetries);
      }
    },

    atualizarCodigoIbgeRemetente() {
      const cidadeSelecionada = this.municipiosRemetente.find(
        (cidade) => cidade.nome === this.itemNotaFiscal.municipioRemetente
      );

      if (cidadeSelecionada) {
        this.itemNotaFiscal.codigoIbgeRemetente = cidadeSelecionada.codigoIbge;
      }
    },

    atualizarDescMuncRemetente(data) {
      if (data.codigoIbgeRemetente) {
        const cidadeCorrespondente = this.municipiosRemetente.find(
          (muncDesc) => {
            return muncDesc.codigoIbge === data.codigoIbgeRemetente;
          }
        );

        if (cidadeCorrespondente) {
          this.itemNotaFiscal.municipioRemetente = cidadeCorrespondente.munNome;
          console.log(
            "CIDADE REMETENTE: ",
            this.itemNotaFiscal.municipioRemetente
          );
        } else {
          console.log("NAO ACHOU CIDADE REMETENTE");
        }
      }
    },

    atualizarDescMuncDestinatario(data) {
      if (data.codigoIbgeDestinatario) {
        const cidadeCorrespondente = this.municipiosDestinatario.find(
          (muncDesc) => {
            return muncDesc.codigoIbge === data.codigoIbgeDestinatario;
          }
        );

        if (cidadeCorrespondente) {
          this.itemNotaFiscal.municipioDestinatario =
            cidadeCorrespondente.munNome;
          console.log(
            "CIDADE DESTINATARIO: ",
            this.itemNotaFiscal.municipioDestinatario
          );
        } else {
          console.log("NAO ACHOU CIDADE DESTINATARIO");
        }
      }
    },

    atualizarCodigoIbgeMunCarr() {
      const cidadeSelecionada = this.municipiosCarregamento.find(
        (cidade) => cidade.nome === this.itemMdfe.munCarrNome
      );

      if (cidadeSelecionada) {
        this.itemMdfe.munCarrCodigoIbge = cidadeSelecionada.codigoIbge;
      }
    },

    atualizarCodigoIbgeDestinatario() {
      const cidadeSelecionada = this.municipiosDestinatario.find(
        (cidade) => cidade.nome === this.itemNotaFiscal.municipioDestinatario
      );

      if (cidadeSelecionada) {
        this.itemNotaFiscal.codigoIbgeDestinatario =
          cidadeSelecionada.codigoIbge;
      }
    },

    clickBtnSalvar() {
      if (this.item.prepararCte == true) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Cte já em preparação."
        );
        return;
      }

      if (this.item.prepararMdfe == true) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Mdfe já em preparação."
        );
        return;
      }

      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          if (resposta) {
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormulario()) {
              if (this.item.id === 0) {
                EmissaoViagem.insert(this.item)
                  .then((response) => {
                    console.log(
                      "Emissão de Viagem criada com sucesso!",
                      response
                    );
                    this.snackSuccess("Emissão de Viagem criada com sucesso!");
                    this.item.id = response.data.id;
                    this.dialogCadastro = false;
                    this.dialogListaNotaFiscal = true;
                  })
                  .catch((error) => {
                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar a emissao de viagem: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar a emissao de viagem: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              } else {
                EmissaoViagem.update(this.item.id, this.item)
                  .then((response) => {
                    console.log(
                      "Emissão de Viagem atualizada com sucesso!",
                      response
                    );
                    this.snackSuccess(
                      "Emissão de Viagem atualizada com sucesso!"
                    );
                    this.dialogCadastro = false;
                    this.dialogListaNotaFiscal = true;
                    this.filtrar();
                  })
                  .catch((error) => {
                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar a emissão de viagem: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar a emissão de viagem: " +
                          error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              }
            }
            this.setLoading(false);
          }
        }
      );
    },

    clickBtnSalvarNotaFiscal() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          if (resposta) {
            this.processoEmAndamento = true;
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioNotaFiscal()) {
              if (this.itemNotaFiscal.id === 0) {
                EmissaoViagem.insertNotaFiscal(this.itemNotaFiscal)
                  .then((response) => {
                    console.log("Nota Fiscal criada com sucesso!", response);
                    this.snackSuccess("Nota Fiscal criada com sucesso!");

                    this.itemNotaFiscal.id = response.data.id;
                    this.processoEmAndamento = false;
                    this.dialogNotaFiscal = false;
                    this.carregarNotaFiscais(
                      this.itemNotaFiscal.idEmissaoViagem
                    );
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar a Nota Fiscal: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao criar a Nota Fiscal: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              } else {
                EmissaoViagem.updateNotaFiscal(
                  this.itemNotaFiscal.id,
                  this.itemNotaFiscal
                )
                  .then((response) => {
                    console.log(
                      "Nota Fiscal atualizada com sucesso!",
                      response
                    );
                    this.snackSuccess("Nota Fiscal atualizada com sucesso!");
                    this.processoEmAndamento = false;
                    this.dialogNotaFiscal = false;
                    this.carregarNotaFiscais(
                      this.itemNotaFiscal.idEmissaoViagem
                    );
                  })
                  .catch((error) => {
                    this.processoEmAndamento = false;

                    if (error.response) {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar a Nota Fiscal: " +
                          error.response.data.mensagem
                      );
                    } else {
                      this.$refs.message.showMsg(
                        "ERROR!",
                        "Erro ao atualizar a Nota Fiscal: " + error.message
                      );
                    }
                  })
                  .finally(finishLoading);
              }
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    clickBtnSalvarCte() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          if (resposta) {
            this.processoEmAndamento = true;
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioCte()) {
              EmissaoViagem.updateCte(this.itemCte.id, this.itemCte)
                .then((response) => {
                  console.log("CTE atualizada com sucesso!", response);
                  this.snackSuccess("CTE atualizada com sucesso!");
                  this.processoEmAndamento = false;
                  this.dialogCte = false;
                  this.carregarCtes(this.itemCte.idEmissaoViagem);
                })
                .catch((error) => {
                  this.processoEmAndamento = false;

                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao atualizar a CTE: " + error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao atualizar o CTE: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    clickBtnAtualizarCte() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja atualizar os Ctes?",
        (resposta) => {
          if (resposta) {
            this.processoEmAndamento = true;
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioAtualizarCte()) {
              EmissaoViagem.updateAllCtes(this.item.id, this.itemAtualizarCte)
                .then((response) => {
                  console.log("CTEs atualizada com sucesso!", response);
                  this.snackSuccess("CTEs atualizada com sucesso!");
                  this.processoEmAndamento = false;
                  this.carregarCtes(this.item.id);
                })
                .catch((error) => {
                  this.processoEmAndamento = false;

                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao atualizar os CTEs: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao atualizar os CTEs: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    clickBtnSalvarMdfe() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          if (resposta) {
            this.processoEmAndamento = true;
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioMdfe()) {
              EmissaoViagem.updateMdfe(this.itemMdfe.id, this.itemMdfe)
                .then((response) => {
                  console.log("MDFE atualizada com sucesso!", response);
                  this.snackSuccess("MDFE atualizada com sucesso!");
                  this.processoEmAndamento = false;
                  this.dialogMdfe = false;
                  this.carregarMdfes(this.itemMdfe.idEmissaoViagem);
                })
                .catch((error) => {
                  this.processoEmAndamento = false;

                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao atualizar a MDFE: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao atualizar o MDFE: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    clickBtnSalvarMunDesc() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          if (resposta) {
            this.processoEmAndamento = true;
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioMunDesc()) {
              EmissaoViagem.updateMunDesc(
                this.itemMunDesc.id,
                this.itemMunDesc.cep
              )
                .then((response) => {
                  console.log("Mun Desc atualizada com sucesso!", response);
                  this.snackSuccess("Mun Desc atualizada com sucesso!");
                  this.processoEmAndamento = false;
                  this.dialogMunDesc = false;
                  this.carregarMuncDesc(this.itemMdfe.id);
                })
                .catch((error) => {
                  this.processoEmAndamento = false;

                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao atualizar a Mun Desc: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao atualizar o Mun Desc: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    validarDadosFormularioMunDesc() {
      this.itemMunDesc.cep = this.itemMunDesc.cepFormatado.replace(/\D/g, "");

      if (!this.itemMunDesc.cep) {
        this.$refs.message.showMsg("Atenção!", "Campo cep é obrigatório");
        return false;
      }
      return true;
    },

    clickBtnSalvarPercurso() {
      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja salvar as alterações?",
        (resposta) => {
          if (resposta) {
            this.processoEmAndamento = true;
            this.setLoading(true, "Salvando dados...");
            const finishLoading = () => {
              this.setLoading(false);
            };
            if (this.validarDadosFormularioPercurso()) {
              EmissaoViagem.insertPercurso(this.itemPercurso)
                .then((response) => {
                  console.log("Percurso inserido com sucesso!", response);
                  this.snackSuccess("Percurso inserido com sucesso!");
                  this.processoEmAndamento = false;
                  this.dialogPercurso = false;
                  this.carregarPercurso(this.itemMdfe.id);
                })
                .catch((error) => {
                  this.processoEmAndamento = false;

                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao inserir o Percurso: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao inserir o Percurso: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.processoEmAndamento = false;
            }
            this.setLoading(false);
          }
        }
      );
    },

    validarDadosFormularioPercurso() {
      if (!this.itemPercurso.uf) {
        this.$refs.message.showMsg("Atenção!", "Campo UF é obrigatório");
        return false;
      }
      return true;
    },

    clickBtnImportarNotaFiscal() {
      if (!this.xmlNfeConteudo) {
        this.$refs.message.showMsg("Atenção!", "Nenhum xml foi informado!");
        return;
      }

      this.limparDadosNotaFiscal();
      this.limparDadosRemetente();
      this.limparDadosDestinatario();

      this.processoEmAndamento = true;

      EmissaoViagem.getImportarNotaFiscal(this.xmlNfeConteudo)
        .then((response) => {
          const data = response.data;

          this.itemNotaFiscal.chave = data.chave;
          this.itemNotaFiscal.qtdeCargaFormatado = this.formatarCasasDecimais(
            data.qtdeCarga,
            3
          );
          this.itemNotaFiscal.pesoCargaFormatado = this.formatarCasasDecimais(
            data.pesoCarga,
            3
          );
          this.itemNotaFiscal.valorCargaFormatado = this.formatarCasasDecimais(
            data.valorCarga,
            2
          );
          this.itemNotaFiscal.valorCargaFormatadoGrid =
            data.valorCarga.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });

          this.itemNotaFiscal.idRemetente = data.idRemetente || 0;
          this.itemNotaFiscal.razaoSocialRemetente =
            data.razaoSocialRemetente || "";
          this.itemNotaFiscal.nomeFantasiaRemetente =
            data.nomeFantasiaRemetente || "";
          this.itemNotaFiscal.cpfCnpjRemetente = data.cpfCnpjRemetente || "";
          this.itemNotaFiscal.cpfCnpjRemetenteFormatado = this.formatarCpfCnpj(
            data.cpfCnpjRemetente
          );
          this.itemNotaFiscal.inscricaoEstadualRemetente =
            data.inscricaoEstadualRemetente || "";
          this.itemNotaFiscal.logradouroRemetente =
            data.logradouroRemetente || "";
          this.itemNotaFiscal.numeroRemetente = data.numeroRemetente || "";
          this.itemNotaFiscal.complementoRemetente =
            data.complementoRemetente || "";
          this.itemNotaFiscal.bairroRemetente = data.bairroRemetente || "";
          this.itemNotaFiscal.codigoIbgeRemetente =
            data.codigoIbgeRemetente || "";
          this.itemNotaFiscal.municipioRemetente =
            data.municipioRemetente || "";
          this.itemNotaFiscal.cepRemetenteFormatado = data.cepRemetente || "";
          this.itemNotaFiscal.ufRemetente = data.ufRemetente || "";
          this.itemNotaFiscal.foneRemetenteFormatado = data.foneRemetente || "";
          this.itemNotaFiscal.emailRemetente = data.emailRemetente || "";
          this.itemNotaFiscal.observacaoRemetente = "";

          this.itemNotaFiscal.idDestinatario = data.idDestinatario || 0;
          this.itemNotaFiscal.razaoSocialDestinatario =
            data.razaoSocialDestinatario || "";
          this.itemNotaFiscal.nomeFantasiaDestinatario =
            data.nomeFantasiaDestinatario || "";
          this.itemNotaFiscal.cpfCnpjDestinatario =
            data.cpfCnpjDestinatario || "";
          this.itemNotaFiscal.cpfCnpjDestinatarioFormatado =
            this.formatarCpfCnpj(data.cpfCnpjDestinatario);
          this.itemNotaFiscal.inscricaoEstadualDestinatario =
            data.inscricaoEstadualDestinatario || "";
          this.itemNotaFiscal.logradouroDestinatario =
            data.logradouroDestinatario || "";
          this.itemNotaFiscal.numeroDestinatario =
            data.numeroDestinatario || "";
          this.itemNotaFiscal.complementoDestinatario =
            data.complementoDestinatario || "";
          this.itemNotaFiscal.bairroDestinatario =
            data.bairroDestinatario || "";
          this.itemNotaFiscal.codigoIbgeDestinatario =
            data.codigoIbgeDestinatario || "";
          this.itemNotaFiscal.municipioDestinatario =
            data.municipioDestinatario || "";
          this.itemNotaFiscal.cepDestinatarioFormatado =
            data.cepDestinatario || "";
          this.itemNotaFiscal.ufDestinatario = data.ufDestinatario || "";
          this.itemNotaFiscal.foneDestinatarioFormatado =
            data.foneDestinatario || "";
          this.itemNotaFiscal.emailDestinatario = data.emailDestinatario || "";
          this.itemNotaFiscal.observacaoDestinatario = "";
          this.itemNotaFiscal.importada = true;

          this.loadEstados();
          this.loadMunicipiosDestinatario();
          this.loadMunicipiosRemetente();

          this.processoEmAndamento = false;
        })
        .catch((error) => {
          this.processoEmAndamento = false;

          if (error.response) {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao importar Nota Fiscal: " + error.response.data.mensagem
            );
          } else {
            this.$refs.message.showMsg(
              "ERROR!",
              "Erro ao importar Nota Fiscal: " + error.message
            );
          }

          return;
        });

      this.tabNotaFiscal = "dadosNota";
      this.somenteLeituraCnpjRemetente = false;
      this.somenteLeituracpfCnpjDestinatario = false;
      this.dialogImportarNotaFiscal = false;
      this.dialogNotaFiscal = true;
    },

    validarDadosFormulario() {
      if (!this.item.descricao) {
        this.$refs.message.showMsg("Atenção!", "Campo descricao é obrigatório");
        return false;
      }

      if (this.item.descricao.length < 5) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo descricao deve ter no minimo 5 caracteres"
        );
        return false;
      }

      if (this.item.idParticipante <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Transportador é obrigatório"
        );
        return false;
      }

      this.mensagem = "";
      return true;
    },

    validarDadosFormularioNotaFiscal() {
      this.atualizarValoresNotaFiscais();

      if (!this.validarDadosNotaFiscal()) return false;

      if (!this.validarDadosRemetente()) return false;

      if (!this.validarDadosDestinatario()) return false;

      this.mensagem = "";
      return true;
    },

    validarDadosFormularioCte() {
      this.itemCte.qtdeCarga = this.formatarValorDb(
        this.itemCte.qtdeCargaFormatado
      );
      this.itemCte.pesoCarga = this.formatarValorDb(
        this.itemCte.pesoCargaFormatado
      );
      this.itemCte.valorCarga = this.formatarValorDb(
        this.itemCte.valorCargaFormatado
      );
      this.itemCte.valorFrete = this.formatarValorDb(
        this.itemCte.valorFreteFormatado
      );

      if (this.itemCte.modalidade == null || this.itemCte.modalidade < 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo modalidade é obrigatório"
        );

        return false;
      }

      if (!this.itemCte.placa) {
        this.$refs.message.showMsg("Atenção!", "Campo Placa é obrigatório");
        return false;
      }

      if (!this.itemCte.produtoPredominante) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Produto Predominante é obrigatório"
        );
        return false;
      }

      if (!this.itemCte.rntrc) {
        this.$refs.message.showMsg("Atenção!", "Campo rntrc é obrigatório");
        return false;
      }

      if (this.itemCte.rntrc.length != 8) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo rntrc precisa ter 8 caracteres"
        );
        return false;
      }

      if (this.itemCte.qtdeCarga <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Qtde Carga é obrigatório"
        );
        return false;
      }

      if (this.itemCte.pesoCarga <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Peso Carga é obrigatório"
        );
        return false;
      }

      if (this.itemCte.valorFrete <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Valor Frete é obrigatório"
        );
        return false;
      }

      if (this.itemCte.valorCarga <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Valor Carga é obrigatório"
        );
        return false;
      }

      if (this.itemCte.observacao == null || !this.itemCte.observacao) {
        this.itemCte.observacao =
          "DOC " +
          this.itemCte.idEmissaoViagem +
          " - PLACA: " +
          this.itemCte.placa;
      }

      return true;
    },

    validarDadosFormularioAtualizarCte() {
      this.itemAtualizarCte.valorFrete = this.formatarValorDb(
        this.itemAtualizarCte.valorFreteFormatado
      );

      if (!this.itemAtualizarCte.produtoPredominante) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Produto Predominante é obrigatório"
        );
        return false;
      }

      if (this.itemAtualizarCte.valorFrete <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Valor Frete é obrigatório"
        );
        return false;
      }

      if (!this.itemAtualizarCte.observacao) {
        this.$refs.message.showMsg("Atenção!", "Campo Placa é obrigatório");
        return false;
      }

      return true;
    },

    validarDadosFormularioMdfe() {
      this.itemMdfe.valorCarga = this.formatarValorDb(
        this.itemMdfe.valorCargaFormatado
      );
      this.itemMdfe.pesoCarga = this.formatarValorDb(
        this.itemMdfe.pesoCargaFormatado
      );

      if (this.itemMdfe.pesoCarga <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Peso Carga é obrigatório"
        );
        return false;
      }

      if (this.itemMdfe.valorCarga <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Valor Carga é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.munCarrCep) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Cep Carregamento é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.munCarrUf) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo UF Carregamento é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.munCarrNome) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Municipio Carregamento é obrigatório"
        );
        return false;
      }

      if (this.itemMdfe.munCarrCodigoIbge <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Código IBGE Municipio Carregamento é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.munIniCep) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Cep Mun. Inicio é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.munIniUf) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo UF Mun. Inicio é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.munFimCep) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Cep Mun. Fim é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.munFimUf) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo UF Mun. Fim é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.observacao) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Observação é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.prodPredNome) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Produto Predominante é obrigatório"
        );
        return false;
      }

      if (!this.itemMdfe.prodPredCean) {
        this.itemMdfe.prodPredCean = "12345671";
      }

      if (!this.itemMdfe.prodPredNcm) {
        this.itemMdfe.prodPredNcm = "17019900";
      }

      if (this.itemMdfe.idMotorista <= 0) {
        this.$refs.message.showMsg("Atenção!", "Campo Motorista é obrigatório");
        return false;
      }

      if (this.itemMdfe.idVeiculo <= 0) {
        this.$refs.message.showMsg("Atenção!", "Campo Veiculo é obrigatório");
        return false;
      }

      this.itemMdfe.munCarrCep = this.itemMdfe.munCarrCep.replace(/\D/g, "");
      this.itemMdfe.munIniCep = this.itemMdfe.munIniCep.replace(/\D/g, "");
      this.itemMdfe.munFimCep = this.itemMdfe.munFimCep.replace(/\D/g, "");

      return true;
    },

    validarDadosNotaFiscal() {
      if (!this.itemNotaFiscal.chave) {
        this.tabNotaFiscal = "dadosNota";
        this.$refs.chaveInput.focus();
        this.$refs.message.showMsg("Atenção!", "Campo chave é obrigatório");
        return false;
      }

      if (/\s/.test(this.itemNotaFiscal.chave)) {
        this.tabNotaFiscal = "dadosNota";
        this.$refs.chaveInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo chave possui espaço em branco"
        );
        return false;
      }

      if (this.itemNotaFiscal.qtdeCarga <= 0) {
        this.tabNotaFiscal = "dadosNota";
        this.$refs.qtdeCargaFormatadoInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Qtde Volume é obrigatório"
        );
        return false;
      }

      if (this.itemNotaFiscal.pesoCarga <= 0) {
        this.tabNotaFiscal = "dadosNota";
        this.$refs.pesoCargaFormatadoInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Peso Carga é obrigatório"
        );
        return false;
      }

      if (this.itemNotaFiscal.valorCarga <= 0) {
        this.tabNotaFiscal = "dadosNota";
        this.$refs.valorCargaFormatadoInput.focus();
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Valor Carga é obrigatório"
        );
        return false;
      }

      return true;
    },

    validarDadosRemetente() {
      if (!this.itemNotaFiscal.cpfCnpjRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo CNPJ (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.cpfCnpjRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!this.validarCNPJ(this.itemNotaFiscal.cpfCnpjRemetente)) {
        this.$refs.message.showMsg(
          "Atenção!",
          "CNPJ inválido (Remetente)! Favor tente novamente."
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.cpfCnpjRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.razaoSocialRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Razão Social (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.razaoSocialRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.nomeFantasiaRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Nome Fantasia (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.nomeFantasiaRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.logradouroRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Logradouro (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.logradouroRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.numeroRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Número (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.numeroRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.cepRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo CEP (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.cepRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.bairroRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Bairro (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.bairroRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.municipioRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Munícipio (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.municipioRemetenteInput.focus();
        }, 0);
        return false;
      }

      if (!this.itemNotaFiscal.ufRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo UF (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.ufRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (this.itemNotaFiscal.codigoIbgeRemetente <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Código do IBGE Remetente (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.municipioRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.foneRemetente) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Fone (Remetente) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.foneRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(this.itemNotaFiscal.razaoSocialRemetente)
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Razão Social (Remetente) deve conter apenas caracteres alfanuméricos, pontos, vírgulas e barras"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.razaoSocialRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(this.itemNotaFiscal.nomeFantasiaRemetente)
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Nome Fantasia (Remetente) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.nomeFantasiaRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(this.itemNotaFiscal.logradouroRemetente)
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Logradouro (Remetente) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.logradouroRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.itemNotaFiscal.numeroRemetente)) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Numero (Remetente) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.numeroRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (!/^[a-zA-Z0-9\s.,/.-]+$/.test(this.itemNotaFiscal.bairroRemetente)) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Bairro (Remetente) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.bairroRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (
        this.itemNotaFiscal.complementoRemetente &&
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(this.itemNotaFiscal.complementoRemetente)
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Complemento (Remetente) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.complementoRemetenteInput.focus();
        }, 0);

        return false;
      }

      if (
        this.itemNotaFiscal.observacaoRemetente &&
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(this.itemNotaFiscal.observacaoRemetente)
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Observação (Remetente) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "remetente";
          this.$refs.observacaoRemetenteInput.focus();
        }, 0);

        return false;
      }

      return true;
    },

    validarDadosDestinatario() {
      this.itemNotaFiscal.cpfCnpjDestinatario =
        this.itemNotaFiscal.cpfCnpjDestinatarioFormatado.replace(/\D/g, "");

      if (!this.itemNotaFiscal.cpfCnpjDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo CPF/CNPJ (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.cpfCnpjDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (
        !this.validarCNPJ(this.itemNotaFiscal.cpfCnpjDestinatario) &&
        this.itemNotaFiscal.length == 14
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "CNPJ inválido (Destinatario)! Favor tente novamente."
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.cpfCnpjDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (
        !this.validarCPF(this.itemNotaFiscal.cpfCnpjDestinatario) &&
        this.itemNotaFiscal.length == 11
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "CPF inválido (Destinatario)! Favor tente novamente."
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.cpfCnpjDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.razaoSocialDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Razão Social (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.razaoSocialDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.nomeFantasiaDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Nome Fantasia (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.nomeFantasiaDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.logradouroDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Logradouro (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.logradouroDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.numeroDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Número (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.numeroDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.cepDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo CEP (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.cepDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.bairroDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Bairro (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.bairroDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.municipioDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Munícipio (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.municipioDestinatarioInput.focus();
        }, 0);
        return false;
      }

      if (!this.itemNotaFiscal.ufDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo UF (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.ufDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (this.itemNotaFiscal.codigoIbgeDestinatario <= 0) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Código do IBGE Destinatario (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.municipioDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (!this.itemNotaFiscal.foneDestinatario) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Fone (Destinatario) é obrigatório"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.foneDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(
          this.itemNotaFiscal.razaoSocialDestinatario
        )
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Razão Social (Destinatario) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.razaoSocialDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(
          this.itemNotaFiscal.nomeFantasiaDestinatario
        )
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Nome Fantasia (Destinatario) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.nomeFantasiaDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(
          this.itemNotaFiscal.logradouroDestinatario
        )
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Logradouro (Destinatario) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.logradouroDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(this.itemNotaFiscal.numeroDestinatario)
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Numero (Destinatario) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.numeroDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(this.itemNotaFiscal.bairroDestinatario)
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Bairro (Destinatario) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.bairroDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (
        this.itemNotaFiscal.complementoDestinatario &&
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(
          this.itemNotaFiscal.complementoDestinatario
        )
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Complemento (Destinatario) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.complementoDestinatarioInput.focus();
        }, 0);

        return false;
      }

      if (
        this.itemNotaFiscal.observacaoDestinatario &&
        !/^[a-zA-Z0-9\s.,/.-]+$/.test(
          this.itemNotaFiscal.observacaoDestinatario
        )
      ) {
        this.$refs.message.showMsg(
          "Atenção!",
          "Campo Observação (Destinatario) deve conter apenas caracteres alfanuméricos"
        );

        setTimeout(() => {
          this.tabNotaFiscal = "destinatario";
          this.$refs.observacaoDestinatarioInput.focus();
        }, 0);

        return false;
      }

      return true;
    },

    atualizarValoresNotaFiscais() {
      this.itemNotaFiscal.cpfCnpjRemetente =
        this.itemNotaFiscal.cpfCnpjRemetenteFormatado.replace(/\D/g, "");
      this.itemNotaFiscal.cepRemetente =
        this.itemNotaFiscal.cepRemetenteFormatado.replace(/\D/g, "");
      this.itemNotaFiscal.foneRemetente =
        this.itemNotaFiscal.foneRemetenteFormatado.replace(/\D/g, "");
      this.itemNotaFiscal.cpfCnpjDestinatario =
        this.itemNotaFiscal.cpfCnpjDestinatarioFormatado.replace(/\D/g, "");
      this.itemNotaFiscal.cepDestinatario =
        this.itemNotaFiscal.cepDestinatarioFormatado.replace(/\D/g, "");
      this.itemNotaFiscal.foneDestinatario =
        this.itemNotaFiscal.foneDestinatarioFormatado.replace(/\D/g, "");

      // this.itemNotaFiscal.chave = this.itemNotaFiscal.chave.trim();
      // this.itemNotaFiscal.razaoSocialRemetente =
      //   this.itemNotaFiscal.razaoSocialRemetente.trim();
      // this.itemNotaFiscal.nomeFantasiaRemetente =
      //   this.itemNotaFiscal.nomeFantasiaRemetente.trim();
      // this.itemNotaFiscal.emailRemetente =
      //   this.itemNotaFiscal.emailRemetente.trim();
      // this.itemNotaFiscal.logradouroRemetente =
      //   this.itemNotaFiscal.logradouroRemetente.trim();
      // this.itemNotaFiscal.numeroRemetente =
      //   this.itemNotaFiscal.numeroRemetente.trim();
      // this.itemNotaFiscal.complementoRemetente =
      //   this.itemNotaFiscal.complementoRemetente.trim();
      // this.itemNotaFiscal.bairroRemetente =
      //   this.itemNotaFiscal.bairroRemetente.trim();
      // this.itemNotaFiscal.observacaoRemetente =
      //   this.itemNotaFiscal.observacaoRemetente.trim();
      // this.itemNotaFiscal.razaoSocialDestinatario =
      //   this.itemNotaFiscal.razaoSocialDestinatario.trim();
      // this.itemNotaFiscal.nomeFantasiaDestinatario =
      //   this.itemNotaFiscal.nomeFantasiaDestinatario.trim();
      // this.itemNotaFiscal.emailDestinatario =
      //   this.itemNotaFiscal.emailDestinatario.trim();
      // this.itemNotaFiscal.logradouroDestinatario =
      //   this.itemNotaFiscal.logradouroDestinatario.trim();
      // this.itemNotaFiscal.numeroDestinatario =
      //   this.itemNotaFiscal.numeroDestinatario.trim();
      // this.itemNotaFiscal.complementoDestinatario =
      //   this.itemNotaFiscal.complementoDestinatario.trim();
      // this.itemNotaFiscal.bairroDestinatario =
      //   this.itemNotaFiscal.bairroDestinatario.trim();
      // this.itemNotaFiscal.observacaoDestinatario =
      //   this.itemNotaFiscal.observacaoDestinatario.trim();

      this.itemNotaFiscal.qtdeCarga = this.formatarValorDb(
        this.itemNotaFiscal.qtdeCargaFormatado
      );
      this.itemNotaFiscal.pesoCarga = this.formatarValorDb(
        this.itemNotaFiscal.pesoCargaFormatado
      );
      this.itemNotaFiscal.valorCarga = this.formatarValorDb(
        this.itemNotaFiscal.valorCargaFormatado
      );
    },

    clickBtnCancelar() {
      this.dialogCadastro = false;
    },

    clickBtnCancelarListaNotaFiscal() {
      this.dialogListaNotaFiscal = false;
    },

    clickBtnCancelarListaMunDesc() {
      this.dialogMdfe = false;
    },

    clickBtnCancelarListaPerCurso() {
      this.dialogMdfe = false;
    },

    clickBtnCancelarListaCte() {
      this.dialogListaCte = false;
    },

    clickBtnCancelarListaMdfe() {
      this.dialogListaMdfe = false;
    },

    clickBtnFinalizarMdfe() {
      return;
    },

    clickBtnPrepareCte() {
      if (this.listaNotaFiscais.length === 0) {
        this.$refs.message.showMsg("Atenção!", "Nenhuma nota foi encontrada!");
        return;
      }

      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja iniciar a preparação do CTE?",
        (resposta) => {
          if (resposta) {
            this.showLoaderListaNotaFiscais = true;
            this.setLoading(true, "Preparando CTE...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (this.item.id > 0) {
              EmissaoViagem.prepareCte(this.item.id)
                .then((response) => {
                  console.log("Iniciação do Cte Gerada com Sucesso!", response);
                  this.snackSuccess("Iniciação do Cte Gerada com Sucesso!");
                  this.dialogListaNotaFiscal = false;
                  this.showLoaderListaNotaFiscais = false;
                  this.dialogListaCte = true;
                  this.carregarCtes(this.item.id);

                  this.atualizarListaPrepareCte();
                })
                .catch((error) => {
                  this.showLoaderListaNotaFiscais = false;
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao iniciar preparacao CTE: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao iniciar preparacao CTE: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg(
                "ATENÇÃO!",
                "Emissão de Viagem não encontrada!"
              );
            }
          }
          this.setLoading(false);
        }
      );
    },

    validarCte(emissaoViagemCte) {
      this.itemCte = emissaoViagemCte;

      if (this.item.prepararMdfe == true) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Cte ja em preparação."
        );
        return;
      }

      if (this.itemCte.status == "CANCELADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Cte cancelado."
        );
        return;
      }

      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja validar este CTE?",
        (resposta) => {
          if (resposta) {
            this.showLoaderListaCte = true;
            this.setLoading(true, "Validando CTE...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (this.itemCte.id > 0) {
              EmissaoViagem.validarCte(this.itemCte.id)
                .then((response) => {
                  console.log("Cte validado com Sucesso!", response);
                  this.snackSuccess("Cte validado com Sucesso!");
                  this.carregarCtes(this.item.id);
                })
                .catch((error) => {
                  this.showLoaderListaCte = false;
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao validar CTE: " + error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao validar CTE: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg("ATENÇÃO!", "CTE não encontrada!");
            }
          }
          this.setLoading(false);
        }
      );
    },

    validarMdfe(emissaoViagemMdfe) {
      this.itemMdfe = emissaoViagemMdfe;

      if (this.itemMdfe.status == "AUTORIZADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! MDFE já validado."
        );
        return;
      }

      if (this.itemMdfe.status == "CANCELADO") {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! MDFE cancelado."
        );
        return;
      }

      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja validar este MDFE?",
        (resposta) => {
          if (resposta) {
            this.showLoaderListaMdfe = true;
            this.setLoading(true, "Validando MDFE...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (this.itemMdfe.id > 0) {
              EmissaoViagem.validarMdfe(this.itemMdfe.id)
                .then((response) => {
                  console.log("MDFE validada com Sucesso!", response);
                  this.snackSuccess("MDFE validada com Sucesso!");
                  this.carregarMdfes(this.item.id);
                  this.filtrar();
                })
                .catch((error) => {
                  this.showLoaderListaMdfe = false;
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao validar MDFE: " + error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao validar MDFE: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.$refs.message.showMsg(
                "ATENÇÃO!",
                "Emissão de Viagem não encontrada!"
              );
            }
          }
          this.setLoading(false);
        }
      );
    },

    clickBtnPrepareMdfe() {
      if (this.listaCtes.length === 0) {
        this.$refs.message.showMsg("Atenção!", "Nenhuma Cte foi encontrado!");
        return;
      }

      if (this.item.prepararMdfe == true) {
        this.$refs.message.showMsg(
          "ATENÇÃO!",
          "Operação não permitida! Mdfe ja em preparação."
        );
        return;
      }

      this.$refs.message.showConfirm(
        "Confirmação",
        "Deseja iniciar a preparação do MDFE?",
        (resposta) => {
          if (resposta) {
            this.showLoaderListaCte = true;
            this.setLoading(true, "Preparando MDFE...");
            const finishLoading = () => {
              this.setLoading(false);
            };

            if (this.item.id > 0) {
              EmissaoViagem.prepareMdfe(this.item.id)
                .then((response) => {
                  console.log(
                    "Iniciação do Mdfe Gerada com Sucesso!",
                    response
                  );
                  this.snackSuccess("Iniciação do Mdfe Gerada com Sucesso!");
                  this.dialogListaCte = false;
                  this.showLoaderListaCte = false;
                  this.dialogListaMdfe = true;
                  this.carregarMdfes(this.item.id);

                  this.atualizarListaPrepareMdfe();
                })
                .catch((error) => {
                  this.showLoaderListaCte = false;
                  if (error.response) {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao iniciar preparacao MDFE: " +
                        error.response.data.mensagem
                    );
                  } else {
                    this.$refs.message.showMsg(
                      "ERROR!",
                      "Erro ao iniciar preparacao MDFE: " + error.message
                    );
                  }
                })
                .finally(finishLoading);
            } else {
              this.showLoaderListaCte = false;
              this.$refs.message.showMsg(
                "ATENÇÃO!",
                "Emissão de Viagem não encontrada!"
              );
            }
          }
          this.setLoading(false);
        }
      );
    },

    consultarCpf() {
      if (this.somenteLeituraCpf) {
        return;
      }

      if (!this.validarCPF(this.itemMotorista.cpfFormatado)) {
        this.$refs.message.showMsg(
          "Atenção!",
          "CPF inválido! Favor tente novamente."
        );

        this.itemMotorista.nome = "";
        this.itemMotorista.ativo = true;
        this.somenteLeituraCpf = false;

        setTimeout(() => {
          this.$refs.cpfMotoristaInput.focus();
        }, 0);

        return;
      }

      const cpf = this.itemMotorista.cpfFormatado.replace(/\D/g, "");

      Transportador.getCpfMotorista(cpf)
        .then((response) => {
          const data = response.data;

          this.itemMotorista.idMotorista = data.id || 0;
          this.itemMotorista.nome = data.nome || "";
          this.itemMotorista.cpf = data.cpf || "";
          this.itemMotorista.ativo = data.ativo || "";
          (this.itemMotorista.cpfFormatado = this.formatarCpfCnpj(data.cpf)),
            (this.somenteLeituraCpf = true);

          setTimeout(() => {
            this.$refs.nomeMotoristaInput.focus();
          }, 0);
        })
        .catch(() => {
          this.itemMotorista.idMotorista = 0;
          this.itemMotorista.nome = "";
          this.itemMotorista.ativo = true;
          this.somenteLeituraCpf = true;

          setTimeout(() => {
            this.$refs.nomeMotoristaInput.focus();
          }, 0);
        });
    },

    consultarPlaca() {
      if (this.somenteLeituraPlaca) {
        return;
      }

      Transportador.getPlacaVeiculo(this.itemVeiculo.placa)
        .then((response) => {
          const data = response.data;

          this.itemVeiculo.idVeiculo = data.id || 0;
          this.itemVeiculo.tipoVeiculo = data.tipoVeiculo;
          this.itemVeiculo.placa = data.placa || "";
          this.itemVeiculo.renavam = data.renavam || "";
          this.itemVeiculo.tara = data.tara || "";
          this.itemVeiculo.capKg = data.capKg || "";
          this.itemVeiculo.ufLicenciamento = data.ufLicenciamento || "";
          this.itemVeiculo.rntrc = data.rntrc || "";
          this.itemVeiculo.tipoRodado = data.tipoRodado;
          this.itemVeiculo.tipoCarroceria = data.tipoCarroceria;
          this.itemVeiculo.ativo = data.ativo || "";

          this.somenteLeituraPlaca = true;

          setTimeout(() => {
            this.$refs.placaInput.focus();
          }, 0);
        })
        .catch(() => {
          this.limparDadosVeiculo();

          setTimeout(() => {
            this.$refs.renavamInput.focus();
          }, 0);
        });
    },

    limparDadosCompletoMotorista() {
      this.itemMotorista.id = 0;
      this.itemMotorista.cpf = "";
      this.itemMotorista.cpfFormatado = "";
      this.itemMotorista.nome = "";

      this.somenteLeituraCpf = false;
    },

    limparDadosCompletoVeiculo() {
      this.itemMotorista.id = 0;
      this.itemMotorista.placa = "";

      this.limparDadosVeiculo();

      this.somenteLeituraPlaca = false;
    },

    limparDadosVeiculo() {
      this.itemVeiculo.idVeiculo = 0;
      this.itemVeiculo.tipoVeiculo = 0;
      this.itemVeiculo.renavam = "";
      this.itemVeiculo.tara = "";
      this.itemVeiculo.capKg = "";
      this.itemVeiculo.ufLicenciamento = "";
      this.itemVeiculo.rntrc = "";
      this.itemVeiculo.tipoRodado = "";
      this.itemVeiculo.tipoCarroceria = "";
      this.itemVeiculo.ativo = true;
    },

    clickBtnCancelarNotaFiscal() {
      this.dialogNotaFiscal = false;
    },

    clickBtnCancelarCte() {
      this.dialogCte = false;
    },

    clickBtnCancelarMdfe() {
      this.dialogMdfe = false;
    },

    clickBtnCancelarMunDesc() {
      this.dialogMunDesc = false;
    },

    clickBtnCancelarPercurso() {
      this.dialogMunDesc = false;
    },

    clickBtnCancelarImportarNotaFiscal() {
      this.dialogImportarNotaFiscal = false;
    },

    atualizarListaPrepareCte() {
      this.item.prepararCte = true;

      for (let i = 0; i < this.listaEmissaoViagem.length; i++) {
        if (this.item.id == this.listaEmissaoViagem[i].id) {
          this.listaEmissaoViagem[i].prepararCte = true;
          return;
        }
      }
    },

    atualizarListaPrepareMdfe() {
      this.item.prepararMdfe = true;

      for (let i = 0; i < this.listaEmissaoViagem.length; i++) {
        if (this.item.id == this.listaEmissaoViagem[i].id) {
          this.listaEmissaoViagem[i].prepararMdfe = true;
          return;
        }
      }
    },

    onXmlNfeFileChange() {
      if (!this.xmlNfeFile) {
        this.xmlNfeConteudo = "";
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        this.xmlNfeConteudo = event.target.result;
      };

      reader.readAsText(this.xmlNfeFile);
    },

    validarCNPJ(cnpj) {
      // Remove caracteres não numéricos
      cnpj = cnpj.replace(/\D/g, "");

      // Valida o tamanho do CNPJ
      if (cnpj.length !== 14) {
        return false;
      }

      // Valida dígitos verificadores
      const pesos1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      const pesos2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
      const digito1 = cnpj.charAt(12);
      const digito2 = cnpj.charAt(13);
      const calcularDigito = (cnpj, pesos) => {
        const soma = cnpj
          .split("")
          .slice(0, pesos.length)
          .map((d, i) => parseInt(d, 10) * pesos[i])
          .reduce((a, b) => a + b, 0);
        const resto = soma % 11;
        return resto < 2 ? 0 : 11 - resto;
      };

      const valido =
        parseInt(digito1, 10) === calcularDigito(cnpj, pesos1) &&
        parseInt(digito2, 10) === calcularDigito(cnpj, pesos2);

      return valido;
    },

    validarCPF(cpf) {
      // Remove caracteres não numéricos
      cpf = cpf.replace(/\D/g, "");

      // Valida o tamanho do CPF
      if (cpf.length !== 11) {
        return false;
      }

      // Valida dígitos verificadores
      const pesos1 = [10, 9, 8, 7, 6, 5, 4, 3, 2];
      const pesos2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
      const digito1 = cpf.charAt(9);
      const digito2 = cpf.charAt(10);
      const calcularDigito = (cpf, pesos) => {
        const soma = cpf
          .split("")
          .slice(0, pesos.length)
          .map((d, i) => parseInt(d, 10) * pesos[i])
          .reduce((a, b) => a + b, 0);
        const resto = soma % 11;
        return resto < 2 ? 0 : 11 - resto;
      };

      const valido =
        parseInt(digito1, 10) === calcularDigito(cpf, pesos1) &&
        parseInt(digito2, 10) === calcularDigito(cpf, pesos2);

      return valido;
    },

    formatarValorDb(valorOriginal) {
      const valorSemPontoMilhar = valorOriginal.replace(/\./g, "");
      const valorFormatado = valorSemPontoMilhar.replace(",", ".");

      return valorFormatado;
    },

    formatarCasasDecimais(valor, casasDecimais) {
      const numeroArredondado = Number(valor).toFixed(casasDecimais);
      return numeroArredondado;
    },

    convertDate(originalDate) {
      const date = new Date(originalDate);
      const formattedDate = this.formatDate(date);
      const formattedTime = this.formatTime(date);

      return `${formattedDate} ${formattedTime}`;
    },

    formatDate(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${this.padZero(day)}/${this.padZero(month)}/${year}`;
    },

    formatTime(date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(
        seconds
      )}`;
    },

    padZero(value) {
      return value < 10 ? `0${value}` : value;
    },

    getStatusText(status) {
      switch (status) {
        case "EM_PREPARACAO":
          return "EM PREPARACAO";
        case "NAO_ENVIADO":
          return "NÃO ENVIADO";
        case "ERRO":
          return "ERRO";
        case "AUTORIZADO":
          return "AUTORIZADO";
        case "CANCELADO":
          return "CANCELADO";
        default:
          return "DESCONHECIDO";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.vue-treeselect__control {
  border-radius: 0;
}

.background-white {
  background-color: white;
  color: #757575;
}

.background-gray {
  background-color: #757575;
  color: white;
}

.responsivo {
  position: absolute;
  top: -100%;
  width: 100%;
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    position: relative;
    top: 0%;
  }
}
</style>
