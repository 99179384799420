import { httpCteEngine } from "../plugins/http";

let endpoint = "/v1/";

export default {
  getFilteredDocuments: (params) => {
    var cnpjs = [];
    params.transportadores.forEach((item) => {
      cnpjs.push(item.cnpj);
    });
    var map = new Map();
    map.set("Transportador", "&cnpjTransp=");
    map.set("Entrega", "&idConhecimento=");
    map.set("Pagador", "&idFilial=");
    map.set("Placa", "&placa=");
    map.set("Prevista", "&dataPrevista");
    map.set("Pagamento", "&dataPagamento");
    map.set("Emissão", "&dataEmissao");
    map.set("Status", "&status=");
    map.set("", "");
    let url = endpoint + "financeiro/titulos?";
    params.campo == "Transportador" &&
    (params.valor == "" || params.valor == null)
      ? (url += "&cnpjTransp=" + cnpjs.join(","))
      : (url += map.get(params.campo) + params.valor);
    if (params.campo != "Transportador") {
      url += "&cnpjTransp=" + cnpjs.join(",");
    }
    params.tipoData != "Nenhum"
      ? (url +=
          map.get(params.tipoData) +
          "Inicio=" +
          params.dtInicio +
          map.get(params.tipoData) +
          "Fim=" +
          params.dtFim)
      : "";
    params.situacao != 11 ? (url += map.get("Status") + params.situacao) : "";

    return httpCteEngine.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  downloadXLSX: (params) => {
    var cnpjs = [];
    params.transportadores.forEach((item) => {
      cnpjs.push(item.cnpj);
    });
    var map = new Map();
    map.set("Transportador", "&cnpjTransp=");
    map.set("Entrega", "&idConhecimento=");
    map.set("Pagador", "&idFilial=");
    map.set("Placa", "&placa=");
    map.set("Prevista", "&dataPrevista");
    map.set("Pagamento", "&dataPagamento");
    map.set("Emissão", "&dataEmissao");
    map.set("Status", "&status=");
    map.set("", "");
    let url = endpoint + "financeiro/titulos/arquivo?";
    params.campo == "Transportador" &&
    (params.valor == "" || params.valor == null)
      ? (url += "&cnpjTransp=" + cnpjs.join(","))
      : (url += map.get(params.campo) + params.valor);
    if (params.campo != "Transportador") {
      url += "&cnpjTransp=" + cnpjs.join(",");
    }
    params.tipoData != "Nenhum"
      ? (url +=
          map.get(params.tipoData) +
          "Inicio=" +
          params.dtInicio +
          map.get(params.tipoData) +
          "Fim=" +
          params.dtFim)
      : "";
    params.situacao != 11 ? (url += map.get("Status") + params.situacao) : "";

    return httpCteEngine.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  cancelarDocumento: (chaveAcesso, protocolo, justificativa) => {
    let url = `${endpoint}cte/eventos/cancelamento?chaveAcesso=${chaveAcesso}&protocolo=${protocolo}&justificativa=${encodeURIComponent(
      justificativa
    )}`;

    return httpCteEngine.post(url, null, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
  },
};
