import { httpCadastro } from "../plugins/http";

let endpoint = "/v1/cadastro";

export default {
  getEmissaoViagem: (tipoFiltro, valor, status, idUsuario) => {
    let url =
      endpoint +
      "/emissao-viagem" +
      "/busca?tipoFiltro=" +
      tipoFiltro +
      "&valor=" +
      valor +
      "&idUsuario=" +
      idUsuario +
      "&status=" +
      status;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  insert: (EmissoViagemData) => {
    return httpCadastro.post(endpoint + "/emissao-viagem", EmissoViagemData, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  update: (id, EmissoViagemData) => {
    return httpCadastro.put(
      `${endpoint + "/emissao-viagem"}/${id}`,
      EmissoViagemData,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  delete: (id) => {
    return httpCadastro.delete(`${endpoint}/emissao-viagem/${id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },
  

  getCpfCnpj: (cpfCnpj) => {
    let url = endpoint + "/pessoa/cpf-cnpj?cpfCnpj=" + cpfCnpj;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getNotaFiscais: (idEmissaoViagem) => {
    let url =
      endpoint +
      "/emissao-viagem-nota-fiscal" +
      "/busca?idEmissaoViagem=" +
      idEmissaoViagem;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getNotaFiscaisCte: (idEmissaoViagem, idRemetente, idDestinatario) => {
    let url =
      endpoint +
      "/emissao-viagem-nota-fiscal" +
      "/busca-cte?idRemetente=" +
      idRemetente+
      "&idDestinatario=" +
      idDestinatario+
      "&idEmissaoViagem=" +
      idEmissaoViagem;      

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getCtes: (idEmissaoViagem) => {
    let url =
      endpoint +
      "/emissao-viagem-cte" +
      "/busca?idEmissaoViagem=" +
      idEmissaoViagem;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  validarCte: (id) => {
    return httpCadastro.post(`${endpoint}/emissao-viagem-cte/validar-cte/${id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  validarMdfe: (id) => {
    console.log("PASSOU AQUI 3");
    return httpCadastro.post(`${endpoint}/emissao-viagem-mdfe/validar-mdfe/${id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getMdfes: (idEmissaoViagem) => {
    let url =
      endpoint +
      "/emissao-viagem-mdfe" +
      "/busca?idEmissaoViagem=" +
      idEmissaoViagem;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getImportarNotaFiscal: (xml) => {
    return httpCadastro.post(
      endpoint + "/emissao-viagem-nota-fiscal/importar-nfe",
      xml,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/xml"
        }
      }
    );
  },  

  insertNotaFiscal: (EmissoViagemNotaFiscalData) => {
    return httpCadastro.post(
      endpoint + "/emissao-viagem-nota-fiscal",
      EmissoViagemNotaFiscalData,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  updateNotaFiscal: (id, EmissoViagemNotaFiscalData) => {
    return httpCadastro.put(
      `${endpoint + "/emissao-viagem-nota-fiscal"}/${id}`,
      EmissoViagemNotaFiscalData,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  updateCte: (id, EmissoViagemCteData) => {
    return httpCadastro.put(
      `${endpoint + "/emissao-viagem-cte"}/${id}`,
      EmissoViagemCteData,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  updateAllCtes: (idEmissaoViagem, EmissoViagemCteData) => {
    console.log("PASSOU AQUI EmissoViagemCteData: ", EmissoViagemCteData);

    return httpCadastro.put(
      `${endpoint + "/emissao-viagem-cte/atualizar-ctes"}/${idEmissaoViagem}`,
      EmissoViagemCteData,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  updateMdfe: (id, EmissoViagemMdfeData) => {    
    return httpCadastro.put(
      `${endpoint + "/emissao-viagem-mdfe"}/${id}`,
      EmissoViagemMdfeData,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  deleteNotaFiscal: (id) => {
    return httpCadastro.delete(`${endpoint}/emissao-viagem-nota-fiscal/${id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  prepareCte: (idEmissaoViagem) => {
    return httpCadastro.post(`${endpoint}/emissao-viagem-cte/prepare-cte/${idEmissaoViagem}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  prepareMdfe: (idEmissaoViagem) => {  
    return httpCadastro.post(`${endpoint}/emissao-viagem-mdfe/prepare-mdfe/${idEmissaoViagem}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getMotoristas: (idParticipante) => {
    let url =
      endpoint +
      "/motorista" +
      "/busca?idParticipante=" +
      idParticipante;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  getVeiculos: (idParticipante) => {
    let url =
      endpoint +
      "/veiculo" +
      "/busca?idParticipante=" +
      idParticipante;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },
  
  getMunDesc: (idMdfeAvulso) => {
    let url =
      endpoint +
      "/emissao-viagem-mdfe-mun-desc" +
      "/busca?idMdfeAvulso=" +
      idMdfeAvulso;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  updateMunDesc: (id, cep) => {    
    return httpCadastro.put(
      `${endpoint + "/emissao-viagem-mdfe-mun-desc"}/${id}?cep=${cep}`,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  getPercurso: (idMdfeAvulso) => {
    let url =
      endpoint +
      "/emissao-viagem-mdfe-percurso" +
      "/busca?idMdfeAvulso=" +
      idMdfeAvulso;

    return httpCadastro.get(url, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  deletePercurso: (id) => {
    return httpCadastro.delete(`${endpoint}/emissao-viagem-mdfe-percurso/${id}`, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },  

  insertPercurso: (EmissoViagemMdfePercurso) => {
    return httpCadastro.post(endpoint + "/emissao-viagem-mdfe-percurso", EmissoViagemMdfePercurso, {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  },

  alterarStatusCte: (id, status) => {
    return httpCadastro.put(
      `${endpoint + "/emissao-viagem-cte/status-mdfe"}/${id}/${status}`,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

  alterarStatusMdfe: (id, status) => {
    return httpCadastro.put(
      `${endpoint + "/emissao-viagem-mdfe/status-mdfe"}/${id}/${status}`,
      {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      }
    );
  },

};
